import React, { useState, useEffect } from 'react';
import GetHttpConfig from "../Helpers/GetHttpConfig";
import { Button, Col, Container, Row } from 'react-bootstrap';
import Loading from "../Common/Loading";
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import './CompanyOverview.css';
import { Chart, registerables } from 'chart.js';
import {
    createJourneyDistanceBar,
    createJourneyPercentagesBarChart,
    createSpeedingAverageLineChart
} from "../ChartsCommon/CommonCharts";
import {
    createActiveDevicesBar,
    createJourneyCountBar,
    createJourneyDurationTotalBar
} from "../ChartsCommon/CompanyOverviewCharts";

Chart.register(...registerables);

/**
 * CompanyOverview component displays company-wide analytics using various charts.
 * It fetches data from APIs and renders charts accordingly.
 *
 * @param {Object} props - Component props.
 * @param {string} props.userAccessToken - User's access token for API calls.
 * @param {number} props.speedUnit - Unit for speed measurements (0 for miles, 1 for kilometers).
 *
 * @returns {JSX.Element} The rendered CompanyOverview component.
 */
const CompanyOverview = ({ userAccessToken, speedUnit }) => {
    document.title = "AGMT | Overview";

    const [JourneyCountData, setJourneyCountData] = useState([]);
    const [ActiveDevicesData, setActiveDevicesData] = useState([]);
    const [TotalDurationMonth, setTotalDurationMonth] = useState([]);
    const [JourneyDistanceData, setJourneyDistanceData] = useState([]);
    const [JourneyDurationPercentages, setJourneyDurationPercentages] = useState([]);
    const [SpeedingAverageTotal, setSpeedingAverageTotal] = useState([]);
    const [TopDistance, setTopDistance] = useState(null);
    const [TopScore, setTopScore] = useState(null);
    const [TopSpeed, setTopSpeed] = useState(null);
    const [TopDuration, setTopDuration] = useState(null);
    const [loading, setLoading] = useState(true);
    const [IsAverage, setIsAverage] = useState(true);
    const [monthLimit, setMonthLimit] = useState(1);
    const [error, setError] = useState(false);

    /**
     * Fetches all required data from APIs when the component mounts.
     */
    useEffect(() => {
        fetchData();
    }, []);

    /**
     * Fetches leaderboard data when monthLimit changes.
     */
    useEffect(() => {
        miniFetchData();
    }, [monthLimit]);

    /**
     * Fetches all required data from APIs.
     */
    const fetchData = async () => {
        try {
            const dataEndpoints = [
                { url: '/api/DeviceAnalysis/JourneyCount', stateSetter: setJourneyCountData },
                { url: '/api/DeviceAnalysis/ActiveDevicesTotal', stateSetter: setActiveDevicesData },
                { url: '/api/DeviceAnalysis/JourneyDurationTotal', stateSetter: setTotalDurationMonth },
                { url: '/api/DeviceAnalysis/JourneyDurationPercentages', stateSetter: setJourneyDurationPercentages },
                { url: '/api/DeviceAnalysis/JourneyDistanceTotal', stateSetter: setJourneyDistanceData },
                { url: '/api/DeviceAnalysis/SpeedingAverageTotal', stateSetter: setSpeedingAverageTotal },
            ];

            const leaderboardEndpoints = [
                { analysisId: 2, stateSetter: setTopDistance },
                { analysisId: 3, stateSetter: setTopScore },
                { analysisId: 13, stateSetter: setTopSpeed },
                { analysisId: 8, stateSetter: setTopDuration },
            ];

            await Promise.all(dataEndpoints.map(endpoint =>
                fetchDataFromAPI(endpoint.url, endpoint.stateSetter)
            ));

            await Promise.all(leaderboardEndpoints.map(endpoint =>
                fetchLeaderboardData(endpoint.analysisId, endpoint.stateSetter)
            ));

            setLoading(false);
        } catch (error) {
            console.error(error);
            setLoading(false);
            setError(true);
        }
    };

    /**
     * Fetches leaderboard data when monthLimit changes.
     */
    const miniFetchData = async () => {
        try {
            const leaderboardEndpoints = [
                { analysisId: 2, stateSetter: setTopDistance },
                { analysisId: 3, stateSetter: setTopScore },
                { analysisId: 13, stateSetter: setTopSpeed },
                { analysisId: 8, stateSetter: setTopDuration },
            ];

            await Promise.all(leaderboardEndpoints.map(endpoint =>
                fetchLeaderboardData(endpoint.analysisId, endpoint.stateSetter)
            ));
        } catch (error) {
            console.error(error);
            setError(true);
        }
    };

    /**
     * Fetches data from the given API endpoint and updates the corresponding state.
     *
     * @param {string} url - The API endpoint URL.
     * @param {Function} stateSetter - The state setter function to update with the fetched data.
     */
    const fetchDataFromAPI = async (url, stateSetter) => {
        try {
            const response = await fetch(url, GetHttpConfig(userAccessToken));
            if (!response.ok) {
                throw new Error(`Failed to fetch ${url}`);
            }
            const data = await response.json();
            stateSetter(data);
        } catch (error) {
            console.error(error);
            stateSetter(null);
            setError(true);
        }
    };

    /**
     * Fetches leaderboard data for a given analysis ID and updates the corresponding state.
     *
     * @param {number} analysisId - The analysis ID for leaderboard data.
     * @param {Function} stateSetter - The state setter function to update with the fetched data.
     */
    const fetchLeaderboardData = async (analysisId, stateSetter) => {
        try {
            const url = `/api/DeviceAnalysis/GetLeaderboardData?MonthLimit=${monthLimit}&AnalysisId=${analysisId}&Limit=1`;
            const response = await fetch(url, GetHttpConfig(userAccessToken));
            if (!response.ok) {
                throw new Error(`Failed to fetch leaderboard data for analysis ID ${analysisId}`);
            }
            const data = await response.json();
            stateSetter(data[0] || null);
        } catch (error) {
            console.error(error);
            stateSetter(null);
            setError(true);
        }
    };

    /**
     * Toggles between average and total data display.
     */
    const toggleData = () => {
        setIsAverage(prevIsAverage => !prevIsAverage);
    };

    /**
     * Toggles the month limit between 1 and 12 and fetches leaderboard data accordingly.
     */
    const toggleMonthLimit = () => {
        setMonthLimit(prevMonthLimit => (prevMonthLimit === 1 ? 12 : 1));
    };

    const toggleActiveDevicesData = IsAverage ? null : ActiveDevicesData;

    let journeyCountContent, activeDevicesContent, durationTotalCount, deviceInfos, JourneyDurationPercentageContent, JourneyDistanceContent, SpeedingAverageTotalContent;

    if (loading || speedUnit === null) {
        journeyCountContent = activeDevicesContent = durationTotalCount = JourneyDistanceContent = JourneyDurationPercentageContent = SpeedingAverageTotalContent = deviceInfos = <Loading />;
    } else if (error) {
        journeyCountContent = activeDevicesContent = durationTotalCount = JourneyDistanceContent = JourneyDurationPercentageContent = SpeedingAverageTotalContent = <p>There was an error.</p>;
    } else {
        const { journeyCountBar, journeyBarOptions } = createJourneyCountBar(JourneyCountData, toggleActiveDevicesData);
        const { activeDevicesBar, activeDevicesOptions } = createActiveDevicesBar(ActiveDevicesData);
        const { journeyDurationTotalBar, journeyDurationBarOptions } = createJourneyDurationTotalBar(TotalDurationMonth, toggleActiveDevicesData);
        const { journeyPercentagesBarChart, journeyPercentagesBarChartOptions } = createJourneyPercentagesBarChart(JourneyDurationPercentages, ActiveDevicesData);
        const { journeyDistanceBar, journeyDistanceOptions } = createJourneyDistanceBar(JourneyDistanceData, toggleActiveDevicesData, speedUnit);
        const { speedingLineChart, speedingLineChartOptions } = createSpeedingAverageLineChart(SpeedingAverageTotal, ActiveDevicesData);

        journeyCountContent = (
            <div>
                <Bar data={journeyCountBar} options={journeyBarOptions} plugins={[ChartDataLabels]} />
            </div>
        );

        activeDevicesContent = (
            <div>
                <Bar data={activeDevicesBar} options={activeDevicesOptions} plugins={[ChartDataLabels]} />
            </div>
        );

        durationTotalCount = (
            <div>
                <Bar data={journeyDurationTotalBar} options={journeyDurationBarOptions} plugins={[ChartDataLabels]} />
            </div>
        );

        JourneyDurationPercentageContent = (
            <div>
                <Bar data={journeyPercentagesBarChart} options={journeyPercentagesBarChartOptions} plugins={[ChartDataLabels]} />
            </div>
        );

        JourneyDistanceContent = (
            <div>
                <Bar data={journeyDistanceBar} options={journeyDistanceOptions} plugins={[ChartDataLabels]} />
            </div>
        );

        SpeedingAverageTotalContent = (
            <div>
                <Bar data={speedingLineChart} options={speedingLineChartOptions} plugins={[ChartDataLabels]} />
            </div>
        );

        deviceInfos = (
            <div className="webcontainer">
                <Row>
                    <Col md={6} lg={2} className="chart-col">
                        <div className="chart-wrapper-company">
                            <h5>Active Vehicles</h5>
                            <h5>{ActiveDevicesData?.month0 || 'No Data'}</h5>
                        </div>
                    </Col>
                    <Col md={6} lg={2} className="chart-col">
                        <div className="chart-wrapper-company">
                            <h5>Total Journeys</h5>
                            <h5>{JourneyCountData?.month0 || 'No Data'}</h5>
                        </div>
                    </Col>
                    <Col md={6} lg={2} className="chart-col">
                        <div className="chart-wrapper-company">
                            <h5>Top Duration</h5>
                            {TopDuration ? (
                                <>
                                    <p>{TopDuration.vehicleReference}</p>
                                    <h5>{Math.floor(TopDuration.leaderboardValue / 3600)}h {Math.floor((TopDuration.leaderboardValue % 3600) / 60)}m</h5>
                                </>
                            ) : 'No Data'}
                        </div>
                    </Col>
                    <Col md={6} lg={2} className="chart-col">
                        <div className="chart-wrapper-company">
                            <h5>Top Distance</h5>
                            {TopDistance ? (
                                <>
                                    <p>{TopDistance.vehicleReference}</p>
                                    <h5>
                                        {speedUnit === 0
                                            ? `${Math.round(TopDistance.leaderboardValue / 1.60934)} miles`
                                            : `${Math.round(TopDistance.leaderboardValue)} kms`}
                                    </h5>
                                </>
                            ) : 'No Data'}
                        </div>
                    </Col>
                    <Col md={6} lg={2} className="chart-col">
                        <div className="chart-wrapper-company">
                            <h5>Top Overall Score</h5>
                            {TopScore ? (
                                <>
                                    <p>{TopScore.vehicleReference}</p>
                                    <h5>{TopScore.leaderboardValue.toFixed(0)}</h5>
                                </>
                            ) : 'No Data'}
                        </div>
                    </Col>
                    <Col md={6} lg={2} className="chart-col">
                        <div className="chart-wrapper-company">
                            <h5>Top Speed</h5>
                            {TopSpeed ? (
                                <>
                                    <p>{TopSpeed.vehicleReference}</p>
                                    <h5>
                                        {speedUnit === 0
                                            ? `${Math.round(TopSpeed.leaderboardValue * 2.23694)} mph`
                                            : `${Math.round(TopSpeed.leaderboardValue * 3.6)} kph`}
                                    </h5>
                                </>
                            ) : 'No Data'}
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }

    return (
        <div className="webcontainer">
            <Container fluid>
                <Row>
                    <Col md={12} lg={12} className="fixed-col2">
                        <div className="contentvehicle3" style={{ marginTop: '10px' }}>
                            <h4>Overview</h4>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={4} lg={4} className="fixed-col2">
                        <div className="contentvehicle3" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '10px' }}>
                            <Button onClick={toggleMonthLimit} style={{ marginLeft: '10px', visibility: 'hidden' }}>
                                <span style={{ width: '60px', display: 'inline-block', textAlign: 'center' }}>
                                    {monthLimit === 1 ? 'Month' : 'Year'}
                                </span>
                            </Button>
                            <h5 style={{ margin: '0 auto' }}>Monthly Overview</h5>
                            <div style={{ width: '90px' }}>
                            </div>
                        </div>
                    </Col>
                    <Col md={8} lg={8} className="fixed-col2">
                        <div className="contentvehicle3" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '10px' }}>
                            <h5 style={{ margin: '0 auto' }}>Top Performers</h5>
                            <Button onClick={toggleMonthLimit} style={{ marginLeft: '10px' }} variant={"outline-primary"}>
                                <span style={{ width: '60px', display: 'inline-block', textAlign: 'center', marginTop: '-2px' }}>
                                    {monthLimit === 1 ? 'Month' : 'Year'}
                                </span>
                            </Button>
                        </div>
                    </Col>
                </Row>

                {deviceInfos}

                <Row>
                    <Col md={12} lg={12} className="fixed-col2">
                        <div className="contentvehicle3" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '10px' }}>
                            <Button onClick={toggleData} style={{ marginLeft: '10px' }} variant={"outline-primary"}>
                                {IsAverage ? (
                                    <span style={{ width: '60px', display: 'inline-block', textAlign: 'center' }}>Total</span>
                                ) : (
                                    <span style={{ width: '60px', display: 'inline-block', textAlign: 'center' }}>Average</span>
                                )}
                            </Button>
                            <h5 style={{ margin: '0 auto' }}>Year Overview</h5>
                            <div style={{ width: '100px' }}>
                            </div>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col md={4} lg={4} className="chart-col">
                        <div className="chart-wrapper-company-overview">
                            <p className="chart-title">Active Devices</p>
                            {activeDevicesContent}
                        </div>
                    </Col>
                    <Col md={4} lg={4} className="chart-col">
                        <div className="chart-wrapper-company-overview">
                            <p className="chart-title">Journeys</p>
                            {journeyCountContent}
                        </div>
                    </Col>
                    <Col md={4} lg={4} className="chart-col">
                        <div className="chart-wrapper-company-overview">
                            <p className="chart-title">Duration</p>
                            {durationTotalCount}
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={4} lg={4} className="chart-col">
                        <div className="chart-wrapper-company-overview">
                            <p className="chart-title">Speeding Percentage</p>
                            {SpeedingAverageTotalContent}
                        </div>
                    </Col>
                    <Col md={4} lg={4} className="chart-col">
                        <div className="chart-wrapper-company-overview">
                            <p className="chart-title">Driving Duration</p>
                            {JourneyDurationPercentageContent}
                        </div>
                    </Col>
                    <Col md={4} lg={4} className="chart-col">
                        <div className="chart-wrapper-company-overview">
                            <p className="chart-title">Distance</p>
                            {JourneyDistanceContent}
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default CompanyOverview;
