import React, { useEffect, useState } from 'react';
import { useSortBy, useTable } from 'react-table';
import GetHttpConfig from "../../Helpers/GetHttpConfig";
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import Loading from "../../Common/Loading";
import '../../Vehicle/VehicleList.css';
import Moment from "react-moment";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from "react-router-dom";
import { PagingInfiniteLarge } from "../../Helpers/PagingInfiniteLarge";
import { throttle } from 'lodash';

/**
 * VehicleManagement component for managing vehicles.
 * Provides functionality to view, search, and manage vehicles, including deleting vehicles.
 *
 * @param {Object} props - The component props.
 * @param {string} props.userAccessToken - User's access token.
 * @param {string} props.userEmail - User's email address.
 * @returns {JSX.Element} The rendered component.
 */
export const VehicleManagement = ({ userAccessToken, userEmail }) => {
    document.title = "AGMT | Vehicles";

    // State variables
    const [vehicleData, setVehicleData] = useState([]);
    const [vehicleRefs, setVehicleRefs] = useState(new Set());
    const [pagesFetched, setPagesFetched] = useState(new Set());
    const [pagesFetching, setPagesFetching] = useState(new Set());
    const [pageSize, setPageSize] = useState(30);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalResults, setTotalResults] = useState(0);
    const [numberOfPages, setNumberOfPages] = useState(1);
    const [fetching, setFetching] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [queryParam, setQueryParam] = useState('');
    const [hoverIndex, setHoverIndex] = useState(-1);

    // Define columns for react-table
    const columns = React.useMemo(
        () => [
            { Header: 'Account', accessor: 'accountReference' },
            { Header: 'Vehicle', accessor: 'vehicleReference' },
            { Header: 'Device', accessor: 'deviceReference' },
            { Header: 'Device Type', accessor: 'deviceType' },
            { Header: 'Bluetooth Number', accessor: 'bluetoothNumber' },
            { Header: 'Firmware Version', accessor: 'firmwareVersion' },
            {
                Header: 'Provisioned',
                accessor: 'createdDateTime',
                Cell: ({ value }) => <Moment fromNow>{value}</Moment>
            },
            {
                Header: 'Last Upload',
                accessor: 'lastPacketReceived',
                Cell: ({ value }) => <Moment fromNow>{value}</Moment>
            },
            {
                Header: '',
                accessor: 'actions1',
                Cell: ({ row }) => {
                    if (row.original.deviceType === 'IC7.O') {
                        return (
                            <div>
                                <Link to={`/move-device?deviceReference=${encodeURIComponent(row.original.deviceReference)}&accountReference=${encodeURIComponent(row.original.accountReference)}&vehicleReference=${encodeURIComponent(row.original.vehicleReference)}`}>
                                    <Button variant="outline-warning" size="sm">Change Driver</Button>
                                </Link>{' '}
                            </div>
                        );
                    }
                    return (
                        <div>
                            <Button variant={"outline-dark"} disabled={true} size="sm">Change Driver</Button>
                        </div>
                    );
                }
            },
            {
                Header: '',
                accessor: 'actions',
                Cell: ({ row }) => {
                    if (row.original.deviceType === 'IC7.O') {
                        return (
                            <div>
                                <Link to={`/switch-customer?deviceReference=${encodeURIComponent(row.original.deviceReference)}`}>
                                    <Button variant="outline-primary" size="sm">Switch Customer</Button>
                                </Link>{' '}
                            </div>
                        );
                    }
                    return (
                        <div>
                            <Button variant={"outline-dark"} disabled={true} size="sm">Switch Customer</Button>
                        </div>
                    );
                }
            },
            {
                Header: '',
                accessor: 'actions2',
                Cell: ({ row }) => (
                    <div>
                        <Button
                            variant="outline-danger"
                            size="sm"
                            onClick={() => removeVehicle(row.original.vehicleReference)}
                        >
                            Delete Device
                        </Button>
                    </div>
                )
            },
        ],
        []
    );

    // Configure the table with columns and data
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
        {
            columns,
            data: vehicleData
        },
        useSortBy
    );

    // Fetch vehicles when pageSize, pageNumber, or queryParam change
    useEffect(() => {
        throttledGetVehicles(pageSize, pageNumber, queryParam);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageSize, pageNumber, queryParam]);

    /**
     * Fetches vehicles from the API and updates the state.
     * Prevents duplicate results when scrolling quickly.
     *
     * @param {number} pageSize - The number of results per page.
     * @param {number} pageNumber - The current page number.
     * @param {string} queryParam - Additional query parameters for filtering.
     */
    const getVehicles = async (pageSize, pageNumber, queryParam) => {
        if (pagesFetched.has(pageNumber) || pagesFetching.has(pageNumber)) {
            // This page is already fetched or being fetched
            return;
        }

        setPagesFetching(prev => new Set(prev).add(pageNumber));
        setFetching(true);

        try {
            const response = await fetch(
                `/api/Vehicle/GetVehicles?PagingRequest.PageSize=${pageSize}&PagingRequest.PageNumber=${pageNumber}&${queryParam}&GetDeviceManagerData=true`,
                GetHttpConfig(userAccessToken)
            );
            const data = await response.json();

            setVehicleData(prevData => {
                const newVehicles = data.results.filter(vehicle => !vehicleRefs.has(vehicle.vehicleReference));

                setVehicleRefs(prevRefs => {
                    const updatedRefs = new Set(prevRefs);
                    newVehicles.forEach(vehicle => updatedRefs.add(vehicle.vehicleReference));
                    return updatedRefs;
                });

                return [...prevData, ...newVehicles];
            });

            setTotalResults(data.pageDetail.totalResults);
            setNumberOfPages(data.pageDetail.numberOfPages);
            setPageNumber(data.pageDetail.pageNumber);

            setPagesFetched(prev => new Set(prev).add(pageNumber));
        } catch (error) {
            console.error('Fetch error:', error);
            setVehicleData([]);
            setTotalResults(0);
            setNumberOfPages(1);
            setPageNumber(1);
            toast.error('Error fetching vehicles');
        } finally {
            setPagesFetching(prev => {
                const updatedSet = new Set(prev);
                updatedSet.delete(pageNumber);
                return updatedSet;
            });
            setFetching(false);
        }
    };

    // Throttle getVehicles to prevent rapid successive calls
    const throttledGetVehicles = throttle(getVehicles, 500);

    /**
     * Handles the removal of a vehicle after user confirmation.
     *
     * @param {string} vehicleReference - The vehicle reference to remove.
     */
    const removeVehicle = async (vehicleReference) => {
        const isConfirmed = window.confirm(`Are you sure you want to delete vehicle ${vehicleReference}?`);
        if (!isConfirmed) return;

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + userAccessToken
            },
            body: JSON.stringify({ vehicleReference })
        };

        try {
            const response = await fetch('/api/VehicleRemoval/RemoveVehicle', requestOptions);

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.errorMessage || `HTTP error! Status: ${response.status}`);
            }

            toast.success('Vehicle removed successfully');
            setVehicleData(prevData => prevData.filter(v => v.vehicleReference !== vehicleReference));
        } catch (error) {
            console.error('Error during vehicle removal:', error);
            toast.error(`Error during vehicle removal: ${error.message}`);
        }
    };

    /**
     * Handles the vehicle search form submission.
     *
     * @param {React.FormEvent<HTMLFormElement>} event - The form submission event.
     */
    const searchVehicles = event => {
        event.preventDefault();
        resetData();
        setQueryParam(`vehicleReference=${encodeURIComponent(searchQuery)}`);
    };

    /**
     * Resets the data and relevant state variables when applying new filters or searches.
     */
    const resetData = () => {
        setVehicleData([]);
        setVehicleRefs(new Set());
        setPagesFetched(new Set());
        setPagesFetching(new Set());
        setTotalResults(0);
        setNumberOfPages(1);
        setPageNumber(1);
    };

    /**
     * Handles changes to the vehicle reference search input.
     *
     * @param {React.ChangeEvent<HTMLInputElement>} event - The input change event.
     */
    const handleVehicleReferenceChange = (event) => {
        setSearchQuery(event.target.value);
    };

    return (
        <div className="webcontainer">
            <ToastContainer position="top-right" autoClose={5000} />
            <Container fluid>
                <Row>
                    <Col md={12} lg={12} className="fixed-col2">
                        <div className="contentvehicle3">
                            <h4>Vehicle Management</h4>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={2} lg={2} className="fixed-col1">
                        <div className="contentsearch">
                            <Form onSubmit={searchVehicles}>
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Control
                                        type="text"
                                        value={searchQuery}
                                        onChange={handleVehicleReferenceChange}
                                        placeholder="Search Vehicles"
                                    />
                                </Form.Group>
                            </Form>
                        </div>
                    </Col>
                    <Col md={2} lg={2} className="fixed-col1">
                        <div className="myvehicles-button">
                            <Link to={`/register/registerappaccount`}>
                                <Button variant="info" className="full-button">
                                    Register App Account
                                </Button>
                            </Link>
                        </div>
                    </Col>
                    <Col md={2} lg={2} className="fixed-col1">
                        <div className="myvehicles-button">
                            <Link to={`/register/registerdeviceIC6`}>
                                <Button variant="primary" className="full-button">
                                    Register IC6
                                </Button>
                            </Link>
                        </div>
                    </Col>
                    <Col md={2} lg={2} className="fixed-col1">
                        <div className="myvehicles-button">
                            <Link to={`/register/registerdevice`}>
                                <Button variant="warning" className="full-button">
                                    Register IC7
                                </Button>
                            </Link>
                        </div>
                    </Col>
                    <PagingInfiniteLarge
                        content={
                            <div className="content-vehicle-list">
                                <div className="table-responsive">
                                    <table {...getTableProps()} className="table table-striped table-dark">
                                        <thead>
                                        {headerGroups.map(headerGroup => (
                                            <tr {...headerGroup.getHeaderGroupProps()}>
                                                {headerGroup.headers.map(column => (
                                                    <th {...column.getHeaderProps(column.getSortByToggleProps())}
                                                        className="cell-padding">
                                                        {column.render('Header')}
                                                        <span>
                                                                {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                                                            </span>
                                                    </th>
                                                ))}
                                            </tr>
                                        ))}
                                        </thead>

                                        <tbody {...getTableBodyProps()}>
                                        {rows.map((row, i) => {
                                            prepareRow(row);
                                            return (
                                                <tr
                                                    {...row.getRowProps()}
                                                    className={i === hoverIndex ? "stackable" : ""}
                                                    onMouseOver={() => setHoverIndex(i)}
                                                    onMouseOut={() => setHoverIndex(-1)}
                                                    style={
                                                        i === hoverIndex
                                                            ? { backgroundColor: "#094f7e", cursor: 'pointer' }
                                                            : {}
                                                    }
                                                >
                                                    {row.cells.map(cell => {
                                                        return (
                                                            <td {...cell.getCellProps()} className="stackable">
                                                                {cell.render('Cell')}
                                                            </td>
                                                        );
                                                    })}
                                                </tr>
                                            );
                                        })}
                                        {fetching && (
                                            <tr>
                                                <td colSpan="100%">
                                                    <Loading />
                                                </td>
                                            </tr>
                                        )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        }

                        pageSize={pageSize}
                        pageNumber={pageNumber}
                        totalResults={totalResults}
                        numberOfPages={numberOfPages}
                        onChange={e => throttledGetVehicles(pageSize, e, queryParam)}
                        pageSizeChange={e => {
                            setPageSize(e);
                            resetData();
                        }}
                        pageSizeSet={e => setPageSize(e)}
                        fetching={fetching}
                        loadMore={searchQuery === ''}
                    />
                </Row>
            </Container>
        </div>
    );
};
