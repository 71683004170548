import React, { useCallback, useEffect, useState } from 'react';
import GetHttpConfig from "../../Helpers/GetHttpConfig";
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import '../User/EditUser.css';

/**
 * AddVehicleGroup Component
 *
 * Provides a form to assign an existing vehicle to a specific group.
 * Fetches available groups on mount and handles form submission to create the vehicle-group association.
 *
 * @param {Object} props - Component properties.
 * @param {string} props.userAccessToken - User's access token for API authorization.
 *
 * @returns {JSX.Element} The rendered component.
 */
const AddVehicleGroup = (props) => {
    const navigate = useNavigate();
    const location = useLocation();

    const urlParam = location.search.substring(1);
    const initialGroupName = decodeURIComponent(urlParam).replace(/%20/g, " ");

    const [NewVehicleGroupData, setNewVehicleGroupData] = useState([]);
    const [DefaultGroupName, setDefaultGroupName] = useState(initialGroupName);
    const [vehicleReference, setVehicleReference] = useState('');
    const [GroupName, setGroupName] = useState('');

    /**
     * useEffect Hook
     *
     * Sets the document title and fetches all available vehicle groups when the component mounts.
     */
    useEffect(() => {
        document.title = "Add Vehicle Group";
        getAllGroups();
    }, []);

    /**
     * Fetches all available vehicle groups from the API.
     *
     * @async
     * @function getAllGroups
     * @throws Will log an error if the fetch fails.
     */
    const getAllGroups = useCallback(async () => {
        try {
            const response = await fetch(`/api/GroupManagement/GetAllGroups`, GetHttpConfig(props.userAccessToken));
            if (!response.ok) {
                throw new Error("Failed to fetch groups");
            }
            const data = await response.json();
            setNewVehicleGroupData(data);
            setGroupName(data[0]?.groupName);
        } catch (error) {
            console.error(error);
        }
    }, [props.userAccessToken]);

    /**
     * Handles the form submission to create a new vehicle-group association.
     *
     * @async
     * @function handleCreateVehicleGroup
     * @param {Event} e - Form submission event.
     * @throws Will log an error if the POST request fails.
     */
    const handleCreateVehicleGroup = async (e) => {
        e.preventDefault();
        let finalGroupName = GroupName === null || GroupName === "Select Group"
            ? (DefaultGroupName === "" || DefaultGroupName == null ? NewVehicleGroupData[0]?.groupName : DefaultGroupName)
            : GroupName;

        const redirectUrl = "/admin/vehiclegroup?" + encodeURIComponent(finalGroupName);

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + props.userAccessToken
            },
            body: JSON.stringify({ vehicleReference, groupName: finalGroupName })
        };

        try {
            const response = await fetch('/api/GroupManagement/AddVehicleGroup', requestOptions);
            if (response.ok) {
                navigate(redirectUrl);
            } else {
                throw new Error("Failed to add vehicle to group");
            }
        } catch (error) {
            console.error(error);
        }
    };

    /**
     * Updates the vehicle reference state based on user input.
     *
     * @function handleVehicleReferenceChange
     * @param {Event} event - Input change event.
     */
    const handleVehicleReferenceChange = (event) => {
        setVehicleReference(event.target.value);
    };

    /**
     * Updates the group name state based on user selection.
     *
     * @function handleGroupNameChange
     * @param {Event} event - Select change event.
     */
    const handleGroupNameChange = (event) => {
        setGroupName(event.target.value);
    };

    return (
        <div className="webcontainer">
            <Container fluid>
                <Row>
                    <Col md={12} lg={12}>
                        <div className="contentvehicle3" style={{ textAlign: 'center' }}>
                            <h5>Add Vehicle Group</h5>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={3} lg={3}></Col>
                    <Col md={6}>
                        <div className="container mt-5">
                            <Form onSubmit={handleCreateVehicleGroup} className="form-container">
                                <Form.Group controlId="newVehicleReference">
                                    <Form.Label>Vehicle</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={vehicleReference}
                                        required
                                        placeholder="Enter a Vehicle"
                                        onChange={handleVehicleReferenceChange}
                                    />
                                    <Form.Text className="text-muted">
                                        Must be an existing vehicle.
                                    </Form.Text>
                                </Form.Group>

                                <Form.Group controlId="group">
                                    <Form.Label>Group</Form.Label>
                                    <select
                                        className="form-control"
                                        onChange={handleGroupNameChange}
                                        value={GroupName || "Select Group"}
                                    >
                                        <option>Select Group</option>
                                        {NewVehicleGroupData.map(group => (
                                            <option value={group.groupName} key={group.groupName}>
                                                {group.groupName}
                                            </option>
                                        ))}
                                    </select>
                                </Form.Group>

                                <Button variant="primary" type="submit">Add Vehicle To Group</Button>
                            </Form>
                        </div>
                    </Col>
                    <Col md={3} lg={3}></Col>
                </Row>
            </Container>
        </div>
    );
};

export default AddVehicleGroup;
