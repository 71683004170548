import React, { useCallback, useEffect, useState } from 'react';
import GetHttpConfig from "../../Helpers/GetHttpConfig";
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import './EditUser.css';

/**
 * EditUser Component
 *
 * Renders a form that allows administrators to edit an existing user's details,
 * including their associated company and preferred measurement unit. It fetches
 * the list of companies and the specific user's current data upon mounting.
 * Handles form submission to update the user's information via an API call.
 * After successful editing, it navigates back to the user management page.
 *
 * @param {Object} props - Component properties.
 * @param {string} props.userAccessToken - User's access token for API authorization.
 *
 * @returns {JSX.Element} The rendered component.
 */
const EditUser = (props) => {
    const navigate = useNavigate();
    const location = useLocation();

    const [NewUserData, setNewUserData] = useState([]);
    const [UserData, setUserData] = useState([]);
    const [Username, setUsername] = useState("");
    const [DefaultCompany, setDefaultCompany] = useState("");
    const [DefaultUnit, setDefaultUnit] = useState("");
    const [companyname, setCompanyname] = useState("");
    const [speedUnit, setSpeedUnit] = useState("0");

    /**
     * useEffect Hook
     *
     * Sets the document title, extracts the username from URL query parameters,
     * and fetches both the list of companies and the specific user's data.
     * This effect runs whenever the URL's search parameters change.
     */
    useEffect(() => {
        document.title = "Edit User";
        const urlParam = location.search;
        let urlValue = urlParam.substring(1);
        let username = urlValue.replace(/%20/g, " ");
        setUsername(username);

        getAllCompanies();
        getUser(username);
    }, [location.search]);

    /**
     * Fetches all companies from the API and updates the state.
     *
     * @async
     * @function getAllCompanies
     */
    const getAllCompanies = async () => {
        try {
            const response = await fetch(`/api/UserManagement/GetAllCompanies`, GetHttpConfig(props.userAccessToken));
            if (!response.ok) {
                throw new Error("Failed to fetch companies");
            }
            const data = await response.json();
            setNewUserData(data);
            setDefaultCompany(data[0].name);
        } catch (error) {
            console.error(error);
        }
    };

    /**
     * Fetches the specific user's data from the API and updates the state.
     *
     * @async
     * @function getUser
     * @param {string} username - The username of the user to fetch.
     */
    const getUser = async (username) => {
        try {
            const response = await fetch(`/api/UserManagement/GetSingleUser?Username=${encodeURIComponent(username)}`, GetHttpConfig(props.userAccessToken));
            if (!response.ok) {
                throw new Error("Failed to fetch user data");
            }
            const data = await response.json();
            setUserData(data);
            setSpeedUnit(data.speedUnit.toString());
            setCompanyname(data.companyName);
        } catch (error) {
            console.error(error);
        }
    };

    /**
     * Handles the form submission to edit the user's details.
     * Sends a POST request to the API with the updated user information.
     * Upon successful editing, navigates to the user management page.
     *
     * @async
     * @function handleEditUser
     * @param {string} username - The username of the user being edited.
     * @param {string} companyname - The new company name selected.
     * @param {Event} e - The form submission event.
     */
    const handleEditUser = useCallback(async (username, companyname, e) => {
        e.preventDefault();

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + props.userAccessToken
            },
            body: JSON.stringify({
                username,
                companyName: companyname,
                isAdmin: false,
                speedUnit: speedUnit
            })
        };

        try {
            const response = await fetch('/api/UserManagement/EditUser', requestOptions);
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.errorMessage || "Failed to edit user");
            }
            navigate('/admin/usermanagement');
        } catch (error) {
            console.error('Failed to edit user:', error);
        }
    }, [companyname, speedUnit, props.userAccessToken, navigate]);

    /**
     * Handles changes to the company select dropdown.
     *
     * @function handleCompanynameChange
     * @param {string} event - The selected company name.
     */
    const handleCompanynameChange = (event) => {
        setCompanyname(event.target.value);
    };

    /**
     * Handles changes to the speed unit select dropdown.
     *
     * @function handleSpeedUnitChange
     * @param {Event} event - The input change event.
     */
    const handleSpeedUnitChange = (event) => {
        setSpeedUnit(event.target.value);
    };

    return (
        <div className="webcontainer">
            <Container fluid>
                <Row>
                    <Col md={12} lg={12} className="fixed-col2">
                        <div className="contentvehicle3">
                            <h4>Edit User</h4>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={3} lg={3}></Col>
                    <Col md={6}>
                        <div className="container mt-5">
                            {/* Edit User Form */}
                            <Form onSubmit={(e) => handleEditUser(Username, companyname, e)} className="form-container">
                                {/* Current Username Display */}
                                <Form.Group controlId="username">
                                    <Form.Label>Current User</Form.Label>
                                    <Form.Control
                                        type="text"
                                        readOnly
                                        value={Username}
                                        placeholder="Username"
                                    />
                                </Form.Group>

                                {/* Company Select Dropdown */}
                                <Form.Group controlId="company">
                                    <Form.Label>Company</Form.Label>
                                    <Form.Control
                                        as="select"
                                        value={companyname}
                                        onChange={handleCompanynameChange}
                                        required
                                    >
                                        <option value="">Select Company</option>
                                        {NewUserData.map(company => (
                                            <option value={company.name} key={company.name}>
                                                {company.name}
                                            </option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>

                                {/* Measurement Unit Select Dropdown */}
                                <Form.Group controlId="speedUnit">
                                    <Form.Label>Measurement Unit <small>(Requires browser refresh)</small></Form.Label>
                                    <Form.Control
                                        as="select"
                                        value={speedUnit}
                                        onChange={handleSpeedUnitChange}
                                        required
                                    >
                                        <option value="0">Imperial</option>
                                        <option value="1">Metric</option>
                                    </Form.Control>
                                </Form.Group>

                                {/* Submit Button */}
                                <Button variant="primary" type="submit">Edit User</Button>
                            </Form>
                        </div>
                    </Col>
                    <Col md={3} lg={3}></Col>
                </Row>
            </Container>
        </div>
    );
};

export default EditUser;
