import React from 'react';

/**
 * JourneyScores Component
 *
 * Displays a set of journey-related scores with color-coded indicators and descriptive labels.
 * Each score represents a specific aspect of the journey, such as Acceleration, Braking, Cornering, Speeding, and Overall performance.
 *
 * @param {Object} props - Component properties.
 * @param {Array} props.journeyScores - An array of score objects, each containing `scoreType` and `score` properties.
 * @param {Array} props.desiredScoreTypes - An array of score type identifiers to filter and display specific scores.
 *
 * @returns {JSX.Element} The rendered JourneyScores component.
 */
const JourneyScores = ({ journeyScores, desiredScoreTypes }) => {
    return (
        <div>
            <div style={{ padding: '10px', display: 'flex', justifyContent: 'space-between', color: 'white' }}>
                {journeyScores
                    .filter((score) => desiredScoreTypes.includes(score.scoreType))
                    .map((score) => {
                        let scoreColor;
                        if (score.score >= 80) {
                            scoreColor = 'green';
                        } else if (score.score >= 50) {
                            scoreColor = 'orange';
                        } else {
                            scoreColor = 'red';
                        }

                        let scoreTypeName;
                        switch (score.scoreType) {
                            case 1:
                                scoreTypeName = 'Acceleration';
                                break;
                            case 2:
                                scoreTypeName = 'Braking';
                                break;
                            case 3:
                                scoreTypeName = 'Cornering';
                                break;
                            case 4:
                                scoreTypeName = 'Speeding';
                                break;
                            case 5:
                                scoreTypeName = 'Overall';
                                break;
                            default:
                                scoreTypeName = '';
                        }

                        return (
                            <p key={score.scoreType} style={{ margin: '0', flexBasis: '25%', textAlign: 'center', fontStyle: 'b' }}>
                <span style={{ color: scoreColor, fontSize: 'x-large', fontWeight: 'bold' }}>
                  {score.score.toFixed(0) + '%'}
                </span>
                                <br /> {scoreTypeName}
                            </p>
                        );
                    })}
            </div>
        </div>
    );
};

export default JourneyScores;
