/**
 * Generates HTTP configuration with authorization headers.
 *
 * @param {string} token - The user's access token.
 * @returns {Object} HTTP config with Bearer token authorization header.
 */
const GetHttpConfig = (token) => {
    return {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    };
};

export default GetHttpConfig;