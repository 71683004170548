import React from 'react';
import Moment from 'react-moment';

/**
 * JourneyTable Component
 *
 * Displays a table of journeys with details such as start and end times, duration, and distance.
 * Allows users to select an active journey by clicking on a table row, which can be used
 * to display more detailed information elsewhere in the application.
 *
 * @param {Object} props - Component properties.
 * @param {Array} props.journeys - An array of journey objects to be displayed in the table.
 * @param {Function} props.handleTableRowClick - Function to handle the selection of a journey.
 * @param {string|null} props.activeJourney - The ID of the currently active (selected) journey.
 * @param {number} props.speedUnit - Unit for displaying distance (0 for miles, 1 for kilometers).
 *
 * @returns {JSX.Element} The rendered JourneyTable component.
 */
const JourneyTable = ({ journeys, handleTableRowClick, activeJourney, speedUnit }) => {
    if (journeys.length === 0) {
        return <p>No journeys reported for unit.</p>;
    }

    return (
        <div className="table-responsive">
            <table className="table table-striped table-dark">
                <thead>
                <tr>
                    <th>When</th>
                    <th>Date</th>
                    <th>Time</th>
                    <th>Duration</th>
                    <th>Distance</th>
                </tr>
                </thead>
                <tbody>
                {journeys.map((journey) => (
                    <tr
                        key={journey.journeyId}
                        onClick={() => handleTableRowClick(journey.journeyId)}
                        className={activeJourney === journey.journeyId ? 'active' : ''}
                    >
                        <td>
                            <Moment fromNow>{journey.startDateTime}</Moment>
                        </td>
                        <td>
                            <Moment format="DD-MM-YYYY">{journey.startDateTime}</Moment>
                        </td>
                        <td>
                            <Moment format="HH:mm:ss">{journey.startDateTime}</Moment>
                            <br />
                            <Moment format="HH:mm:ss">{journey.endDateTime}</Moment>
                        </td>
                        <td>{journey.duration.toFixed(0)} mins</td>
                        <td>
                            {speedUnit === 0
                                ? `${(journey.distance * 0.621371).toFixed(2)} miles`
                                : `${journey.distance.toFixed(2)} kms`}
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};

export default JourneyTable;
