import React, {useState, useEffect, useRef} from 'react';
import GetHttpConfig from "../Helpers/GetHttpConfig";
import {Button, Col, Container, Row, DropdownButton, Dropdown} from 'react-bootstrap';
import BackButtonLogoHolder from "../BackButtonLogoHolder/BackButtonLogoHolder";
import Loading from "../Common/Loading";
import './JourneyList.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import "react-datepicker/dist/react-datepicker.css";
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import {useParams} from 'react-router-dom';
import JourneySearch from "./JourneySearch";
import JourneyTable from "./JourneyTable";
import JourneyScores from "./JourneyScores";
import {convertToUTC} from "../Helpers/DateUtils";


/**
 * JourneyList Component
 *
 * Displays the journeys a device has made, including detailed journey information,
 * interactive map visualizations with event markers, and various controls for filtering and viewing data.
 *
 * @returns {JSX.Element} The rendered JourneyList component.
 * @param userAccessToken - Access token for authenticated API requests.
 * @param speedUnit - Unit for speed measurements (0 for mph, 1 for km/h).
 * @param mapBoxApiKey Mapbox API Key.
 */
export function JourneyList({userAccessToken, speedUnit, mapBoxApiKey}) {
    const params = useParams();
    const vehicleReference = params.vehiclereference;

    // State variables
    const [journeyData, setJourneyData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [pageSize] = useState(50);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalResults, setTotalResults] = useState(0);
    const [numberOfPages, setNumberOfPages] = useState(1);
    const [showPhantom, setShowPhantom] = useState(false);
    const [canLoadMore, setCanLoadMore] = useState(true);
    const [showEventMarkers, setShowEventMarkers] = useState(true);
    const [showSpeedingEventMarkers, setShowSpeedingEventMarkers] = useState(true);
    const [showSearchContent, setShowSearchContent] = useState(false);
    const [isAnimating, setIsAnimating] = useState(true);
    const [isMapMatching, setIsMapMatching] = useState(true);
    const [usePitch, setUsePitch] = useState(true);
    const [mapMatched, setMapMatched] = useState(true);
    const [activeJourney, setActiveJourney] = useState(null);
    const [selectedMapStyle, setSelectedMapStyle] = useState(() => {
        return localStorage.getItem('selectedMapStyle') || 'mapbox://styles/mapbox/standard';
    });

    // Refs
    const mapContainer = useRef(null);
    const map = useRef(null);
    const timeoutRef = useRef(null);
    const eventMarkersRef = useRef([]);
    const speedingEventMarkersRef = useRef([]);
    const debugPositionsRef = useRef(null);
    const startMarkerRef = useRef(null);
    const endMarkerRef = useRef(null);
    const animationFrameIdRef = useRef(null);
    const observerRef = useRef(null);

    /**
     * Sets the document title based on the vehicle reference.
     */
    useEffect(() => {
        document.title = "AGMT | " + vehicleReference + " Journeys";
    }, [vehicleReference]);

    /**
     * Initializes the Mapbox map when the component mounts.
     * Adds controls for fullscreen, navigation, and scale.
     * Cleans up the map instance on component unmount.
     */
    useEffect(() => {
        mapboxgl.accessToken = mapBoxApiKey;
        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: selectedMapStyle,
            zoom: 1
        });

        map.current.addControl(new mapboxgl.FullscreenControl());
        map.current.addControl(new mapboxgl.NavigationControl());
        map.current.addControl(new mapboxgl.ScaleControl());

        // Cleanup on unmount
        return () => {
            if (map.current) {
                map.current.remove();
            }
        };
    }, [mapBoxApiKey]);

    /**
     * Updates the Mapbox map style whenever the selectedMapStyle changes.
     * Saves the selected style to localStorage for persistence.
     */
    useEffect(() => {
        if (map.current && selectedMapStyle) {
            if (animationFrameIdRef.current) {
                cancelAnimationFrame(animationFrameIdRef.current);
                animationFrameIdRef.current = null;
            }

            map.current.setStyle(selectedMapStyle);
            localStorage.setItem('selectedMapStyle', selectedMapStyle);
        }
    }, [selectedMapStyle]);

    /**
     * Adds journey paths and event markers to the map once the map style has loaded.
     * Reacts to changes in activeJourney, journeyData, showEventMarkers, and showSpeedingEventMarkers.
     */
    useEffect(() => {
        const onStyleLoad = () => {
            if (debugPositionsRef.current) {
                addJourneyPathToMap(debugPositionsRef.current);
            }

            if (showEventMarkers && activeJourney) {
                const activeJourneyData = journeyData.find(journey => journey.journeyId === activeJourney);
                if (activeJourneyData && activeJourneyData.accelerometerEvents) {
                    addAccelerometerEventsToMap(activeJourneyData.accelerometerEvents);
                }
            }

            if (showSpeedingEventMarkers && activeJourney) {
                const activeJourneyData = journeyData.find(journey => journey.journeyId === activeJourney);
                if (activeJourneyData && activeJourneyData.speedingEvents) {
                    addSpeedingEventsToMap(activeJourneyData.speedingEvents);
                }
            }
        };

        if (map.current) {
            map.current.on('style.load', onStyleLoad);
        }

        return () => {
            if (map.current) {
                map.current.off('style.load', onStyleLoad);
            }
        };
    }, [activeJourney, journeyData, showEventMarkers, showSpeedingEventMarkers]);

    /**
     * Sets up an IntersectionObserver to implement infinite scrolling.
     * Loads more journeys when the observer element comes into view.
     */
    useEffect(() => {
        const observer = new IntersectionObserver(
            entries => {
                const [entry] = entries;
                if (entry.isIntersecting && canLoadMore && !loading) {
                    loadMoreJourneys();
                }
            },
            {threshold: 1}
        );
        if (observerRef.current) {
            observer.observe(observerRef.current);
        }
        return () => {
            if (observerRef.current) {
                observer.unobserve(observerRef.current);
            }
        };
    }, [canLoadMore, loading]);

    /**
     * Fetches journey data whenever pageNumber or showPhantom changes.
     */
    useEffect(() => {
        getJourneys(pageSize, pageNumber);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageNumber, showPhantom]);

    /**
     * Fetches detailed information for the active journey whenever it changes or map matching is toggled.
     */
    useEffect(() => {
        if (activeJourney !== null && activeJourney !== undefined) {
            fetchJourneyDetails(activeJourney);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeJourney, isMapMatching]);

    /**
     * Updates the mapMatched state based on the active journey's data.
     */
    useEffect(() => {
        const activeJourneyData = journeyData.find(journey => journey.journeyId === activeJourney);
        if (activeJourneyData && (activeJourneyData.mapMatched === true || activeJourneyData.mapMatched === false)) {
            setMapMatched(activeJourneyData.mapMatched);
        }
    }, [journeyData, activeJourney]);

    /**
     * Removes existing accelerometer event markers and adds them again based on current settings.
     */
    useEffect(() => {
        eventMarkersRef.current.forEach(marker => marker.remove());
        eventMarkersRef.current = [];

        if (showEventMarkers && activeJourney) {
            const activeJourneyData = journeyData.find(journey => journey.journeyId === activeJourney);
            if (activeJourneyData && activeJourneyData.accelerometerEvents) {
                addAccelerometerEventsToMap(activeJourneyData.accelerometerEvents);
            }
        }
    }, [showEventMarkers, activeJourney, journeyData]);

    /**
     * Removes existing speeding event markers and adds them again based on current settings.
     */
    useEffect(() => {
        speedingEventMarkersRef.current.forEach(marker => marker.remove());
        speedingEventMarkersRef.current = [];

        if (showSpeedingEventMarkers && activeJourney) {
            const activeJourneyData = journeyData.find(journey => journey.journeyId === activeJourney);
            if (activeJourneyData && activeJourneyData.speedingEvents) {
                addSpeedingEventsToMap(activeJourneyData.speedingEvents);
            }
        }
    }, [showSpeedingEventMarkers, activeJourney, journeyData]);

    /**
     * Toggles the inclusion of phantom (invalid) journeys and resets relevant state variables.
     */
    const toggleIsPhantom = () => {
        setShowPhantom(prevShowPhantom => !prevShowPhantom);
        setJourneyData([]);
        setPageNumber(1);
        setCanLoadMore(true);
    };

    /**
     * Toggles the pitch angle of the map view.
     * Adjusts the map's pitch based on the current setting.
     */
    const toggleIsPitch = () => {
        setUsePitch(prevUsePitch => !prevUsePitch);

        if (map.current) {
            map.current.setPitch(usePitch ? 0 : 40);
        }
    };

    /**
     * Handles the selection of a journey from the table.
     * Sets the selected journey as active.
     *
     * @param {string} journeyId - The ID of the selected journey.
     */
    const handleTableRowClick = (journeyId) => {
        setActiveJourney(journeyId);
    };

    /**
     * Fetches detailed information for a specific journey.
     * Updates journeyData with the fetched details and adds the journey path to the map.
     *
     * @param {string} journeyId - The ID of the journey to fetch details for.
     */
    const fetchJourneyDetails = (journeyId) => {
        const urlPath = `/api/Journey/journey/${journeyId}?UseMapMatching=${isMapMatching}`;

        fetch(urlPath, GetHttpConfig(userAccessToken))
            .then(response => response.json())
            .then(data => {
                setJourneyData(prevJourneyData => {
                    return prevJourneyData.map(journey => {
                        if (journey.journeyId === journeyId) {
                            return {
                                ...journey,
                                details: data.details,
                                accelerometerEvents: data.accelerometerEventPositions,
                                speedingEvents: data.speedingEvents,
                                debugpositions: data.debugPositions,
                                mapMatched: data.mapMatched,
                            };
                        }
                        return journey;
                    });
                });

                if (data.debugPositions) {
                    debugPositionsRef.current = data.debugPositions;
                    addJourneyPathToMap(data.debugPositions);
                } else {
                    console.error('No positions data found in the API response');
                }

            })
            .catch(error => {
                console.error('Error fetching journey details:', error);
            });
    };

    /**
     * Adds the journey path to the Mapbox map.
     * Optionally animates the journey path based on the isAnimating state.
     *
     * @param {Array} debugPositions - Array of position objects containing longitude and latitude.
     */
    const addJourneyPathToMap = (debugPositions) => {
        const sourceId = 'journey';
        const layerId = 'journey-line';

        if (animationFrameIdRef.current) {
            cancelAnimationFrame(animationFrameIdRef.current);
            animationFrameIdRef.current = null;
        }

        if (map.current.getLayer(layerId)) {
            map.current.removeLayer(layerId);
        }
        if (map.current.getSource(sourceId)) {
            map.current.removeSource(sourceId);
        }

        map.current.addSource(sourceId, {
            type: 'geojson',
            data: {
                type: 'FeatureCollection',
                features: [{
                    type: 'Feature',
                    geometry: {
                        type: 'LineString',
                        coordinates: []
                    }
                }]
            },
            lineMetrics: true
        });

        map.current.addLayer({
            id: layerId,
            type: 'line',
            source: sourceId,
            layout: {
                'line-cap': 'round',
                'line-join': 'round',
            },
            paint: {
                'line-width': 8,
                'line-gradient': [
                    'interpolate',
                    ['linear'],
                    ['line-progress'],
                    0, 'rgb(0,239,73)',
                    0.3, 'rgb(0,255,248)',
                    0.8, 'rgb(255,0,166)',
                    1, 'rgb(255,0,0)'
                ]
            }
        });

        const coordinates = debugPositions.map(pos => [pos.longitude, pos.latitude]);

        if (isAnimating) {
            map.current.getSource(sourceId).setData({
                type: 'FeatureCollection',
                features: [{
                    type: 'Feature',
                    geometry: {
                        type: 'LineString',
                        coordinates: []
                    }
                }]
            });
            animateLine(coordinates);
        } else {
            map.current.getSource(sourceId).setData({
                type: 'FeatureCollection',
                features: [{
                    type: 'Feature',
                    geometry: {
                        type: 'LineString',
                        coordinates: coordinates
                    }
                }]
            });
        }

        if (startMarkerRef.current) {
            startMarkerRef.current.remove();
        }
        if (endMarkerRef.current) {
            endMarkerRef.current.remove();
        }

        const startPosition = debugPositions[0];
        const startMarkerElement = document.createElement('div');
        startMarkerElement.className = 'start-marker';

        startMarkerRef.current = new mapboxgl.Marker({ element: startMarkerElement })
            .setLngLat([startPosition.longitude, startPosition.latitude])
            .addTo(map.current);

        const endPosition = debugPositions[debugPositions.length - 1];
        const endMarkerElement = document.createElement('div');
        endMarkerElement.className = 'end-marker';

        endMarkerRef.current = new mapboxgl.Marker({ element: endMarkerElement })
            .setLngLat([endPosition.longitude, endPosition.latitude])
            .addTo(map.current);

        const bounds = coordinates.reduce(
            (acc, coord) => acc.extend(coord),
            new mapboxgl.LngLatBounds(coordinates[0], coordinates[0])
        );

        const screenWidth = window.innerWidth;
        let pitch1 = usePitch ? 50 : 0;

        if (screenWidth <= 700) {
            map.current.fitBounds(bounds, {
                padding: 30,
                maxZoom: 11,
                pitch: pitch1,
            });
        } else {
            map.current.fitBounds(bounds, {
                padding: 50,
                maxZoom: 15,
                pitch: pitch1,
            });
        }
    };

    /**
     * Animates the journey path on the map by progressively adding coordinates.
     *
     * @param {Array} coordinates - Array of longitude and latitude pairs.
     */
    const animateLine = (coordinates) => {
        if (!isAnimating) {
            return;
        }

        const sourceId = 'journey';
        const animationDuration = 4000;
        let startTime = null;

        const totalPositions = coordinates.length;

        const animate = (timestamp) => {
            if (!isAnimating) {
                return;
            }
            if (!startTime) startTime = timestamp;
            const elapsedTime = timestamp - startTime;
            const progress = Math.min(elapsedTime / animationDuration, 1);

            const currentPositions = Math.ceil(totalPositions * progress);
            const nextCoordinates = coordinates.slice(0, currentPositions);

            const source = map.current && map.current.getSource(sourceId);
            if (source) {
                source.setData({
                    type: 'FeatureCollection',
                    features: [{
                        type: 'Feature',
                        geometry: {
                            type: 'LineString',
                            coordinates: nextCoordinates
                        }
                    }]
                });
            } else {
                return;
            }

            if (progress < 1) {
                animationFrameIdRef.current = requestAnimationFrame(animate);
            } else {
                animationFrameIdRef.current = null;
            }
        };

        animationFrameIdRef.current = requestAnimationFrame(animate);
    };

    /**
     * Adds accelerometer event markers to the map based on the provided event positions.
     *
     * @param {Array} accelerometerEventPositions - Array of accelerometer event position objects.
     */
    const addAccelerometerEventsToMap = (accelerometerEventPositions) => {
        const getEventTypeDescription = (eventType) => {
            switch (eventType) {
                case 1:
                    return 'Acceleration';
                case 2:
                    return 'Braking';
                case 3:
                    return 'Cornering';
                case 4:
                    return 'Tamper'
                case 5:
                    return 'Maneuvering'
                default:
                    return 'Unknown';
            }
        };

        const getLevelDescription = (level) => {
            switch (level) {
                case 0:
                    return 'Minor';
                case 1:
                    return 'Medium';
                case 2:
                    return 'Harsh';
                case 3:
                    return 'Extreme';
                default:
                    return 'Unknown';
            }
        };

        if (showEventMarkers) {
            accelerometerEventPositions.forEach((position) => {
                const {latitude, longitude, accelerometerEvents} = position;

                accelerometerEvents.forEach((event) => {
                    const {level, eventType} = event;

                    const markerElement = document.createElement('div');
                    let markerText = '';

                    if (eventType === 1) {
                        markerText = `A${level + 1}`;
                    } else if (eventType === 2) {
                        markerText = `B${level + 1}`;
                    } else if (eventType === 3) {
                        markerText = `C${level + 1}`;
                    } else if (eventType === 4) {
                        markerText = `T`;
                    } else if (eventType === 5) {
                        markerText = `M${level + 1}`;
                    }

                    let markerColor = '';
                    let textColor = ''
                    if (level === 0) {
                        markerColor = 'yellow';
                        textColor = 'black';
                    } else if (level === 1) {
                        markerColor = 'orange';
                        textColor = 'black';
                    } else if (level === 2) {
                        markerColor = 'darkorange';
                        textColor = 'white';
                    } else if (level === 3) {
                        markerColor = 'red';
                        textColor = 'white';
                    }

                    const levelDescription = getLevelDescription(level);

                    markerElement.className = `event-marker event-marker-${markerColor}`;
                    markerElement.style.color = textColor
                    markerElement.innerText = markerText;
                    const marker = new mapboxgl.Marker({element: markerElement})
                        .setLngLat([longitude, latitude])
                        .addTo(map.current);

                    let tooltipContent = `
        <div style="color: #333; font-size: 14px; padding: 10px; background-color: #f9f9f9; border: 1px solid #ddd; border-radius: 4px; text-align: center;">
            <p style="margin: 0">${new Date(event.eventDateTime).toLocaleTimeString()}</p>
            <p style="margin: 0"><strong>Event Type:</strong> ${getEventTypeDescription(eventType)}</p>
            <p style="margin: 0"><strong>Level:</strong> ${level + 1} - ${levelDescription}</p>
        </div>
        `;


                    const tooltip = new mapboxgl.Popup({closeButton: false, closeOnClick: false})
                        .setHTML(tooltipContent);
                    marker.setPopup(tooltip);

                    markerElement.addEventListener('mouseenter', () => {
                        marker.setPopup(tooltip);
                        tooltip.addTo(map.current);
                    });

                    markerElement.addEventListener('mouseleave', () => {
                        tooltip.remove();
                    });

                    eventMarkersRef.current.push(marker);
                });
            });
        }
    };

    /**
     * Adds speeding event markers to the map based on the provided speeding events.
     *
     * @param {Array} speedingEvents - Array of speeding event objects.
     */
    const addSpeedingEventsToMap = (speedingEvents) => {
        if (showSpeedingEventMarkers) {
            speedingEvents.forEach((speedingEvent) => {
                const speedConversionFactor = speedUnit === 0 ? 2.23694 : 3.6;
                const roundingFactor = speedUnit === 0 ? 5 : 10;
                const speed = speedingEvent.speed * speedConversionFactor;
                const speedLimit = Math.round((speedingEvent.speedLimit * speedConversionFactor) / roundingFactor) * roundingFactor;


                if (speedUnit === 0 && speedLimit === 70 && (speed >= 70 && speed <= 82)) {
                    return;
                }

                if (speedUnit === 1 && speedLimit === 110 && (speed >= 110 && speed <= 132)) {
                    return;
                }

                let textColor = ''
                let markerColor = '';
                if (speed / speedLimit > 1.00 && speed / speedLimit <= 1.15) {
                    markerColor = 'yellow';
                    textColor = 'black';
                } else if (speed / speedLimit > 1.15 && speed / speedLimit <= 1.20) {
                    markerColor = 'orange';
                    textColor = 'black';
                } else if (speed / speedLimit > 1.20 && speed / speedLimit <= 1.25) {
                    markerColor = 'darkorange';
                    textColor = 'white';
                } else if (speed / speedLimit > 1.25) {
                    markerColor = 'red';
                    textColor = 'white'

                }


                var el = document.createElement('div');
                el.className = `event-marker event-marker-${markerColor}`;
                el.style.color = textColor
                el.innerText = `${speed.toFixed(0)}`;
                el.style.width = '25px';
                el.style.height = '25px';

                const {latitude, longitude} = speedingEvent.position;

                const marker = new mapboxgl.Marker(el)
                    .setLngLat([longitude, latitude])
                    .addTo(map.current);

                const eventTime = new Date(speedingEvent.eventDateTime).toLocaleTimeString('en-GB', {
                    hour: 'numeric',
                    minute: 'numeric',
                    second: 'numeric',
                });

                const tooltipContent = `
        <div style="color: #333; font-size: 14px; padding: 10px; background-color: #f9f9f9; border: 1px solid #ddd; border-radius: 4px; text-align: center;">
            <p style="margin: 0"><strong>Event Time:</strong> ${eventTime}</p>
        <p style="margin: 0"><strong>Speed:</strong> ${speed.toFixed(1)} ${speedUnit === 0 ? 'mph' : 'km/h'}</p>
        <p style="margin: 0"><strong>Speed Limit:</strong> ${speedLimit.toFixed(0)} ${speedUnit === 0 ? 'mph' : 'km/h'}</p>
        </div>
        `;


                const tooltip = new mapboxgl.Popup({closeButton: false, closeOnClick: false})
                    .setHTML(tooltipContent);
                marker.setPopup(tooltip);

                marker.getElement().addEventListener('mouseenter', () => {
                    marker.setPopup(tooltip);
                    tooltip.addTo(map.current);
                });

                marker.getElement().addEventListener('mouseleave', () => {
                    tooltip.remove();
                });

                speedingEventMarkersRef.current.push(marker);
            });
        }
    };

    /**
     * Toggles the animation state for the journey path.
     */
    const toggleAnimation = () => {
        setIsAnimating(prevIsAnimating => !prevIsAnimating);
    };

    /**
     * Toggles the map matching feature.
     */
    const toggleMapMatching = () => {
        setIsMapMatching(prevIsMapMatching => !prevIsMapMatching);
    };

    /**
     * Loads more journeys by incrementing the page number.
     */
    const loadMoreJourneys = () => {
        if (canLoadMore && !loading) {
            setPageNumber(prevPageNumber => prevPageNumber + 1);
        }
    };

    /**
     * Fetches journey data from the API based on page size, page number, and filters.
     *
     * @param {number} pageSize - Number of journeys per page.
     * @param {number} pageNumber - The page number to fetch.
     */
    const getJourneys = (pageSize, pageNumber) => {
        setLoading(true);
        let urlPath = `/api/Journey/journeys?VehicleReference=${vehicleReference}&PagingRequest.PageSize=${pageSize}&PagingRequest.PageNumber=${pageNumber}&ShowPhantom=${showPhantom}`;
        if (startDate && endDate) {
            urlPath += `&StartDate=${convertToUTC(startDate).toISOString()}&EndDate=${convertToUTC(endDate).toISOString()}`;
        }
        fetch(urlPath, GetHttpConfig(userAccessToken))
            .then(response => response.json())
            .then(data => {
                const journeyDataResponse = data.results;
                const canLoadMoreResponse = data.pageDetail.hasMoreData;

                setJourneyData(prevJourneyData => [...prevJourneyData, ...journeyDataResponse]);
                setLoading(false);
                setError(false);
                setTotalResults(data.pageDetail.totalResults);
                setNumberOfPages(data.pageDetail.numberOfPages);
                setCanLoadMore(canLoadMoreResponse);

                if (journeyDataResponse.length > 0 && !activeJourney) {
                    setActiveJourney(journeyDataResponse[0].journeyId);
                }
            })
            .catch(error => {
                setJourneyData([]);
                setLoading(false);
                setError(true);
                setTotalResults(0);
                setNumberOfPages(1);
                setPageNumber(1);
            });
    };

    /**
     * Initiates a search for journeys based on current filters.
     */
    const searchForJourneys = () => {
        setLoading(true);
        setJourneyData([]);
        setTotalResults(null);
        setNumberOfPages(null);
        setPageNumber(1);
        setCanLoadMore(true);
        getJourneys(pageSize, 1);
    };

    /**
     * Clears the search filters and reloads all journeys.
     */
    const clearSearch = () => {
        setStartDate(null);
        setEndDate(null);
        setLoading(true);
        setJourneyData([]);
        setTotalResults(null);
        setNumberOfPages(null);
        setPageNumber(1);
        setCanLoadMore(true);
        getJourneys(pageSize, 1);
    };

    /**
     * Toggles the visibility of the search form.
     */
    const toggleSearchContent = () => {
        setShowSearchContent(prevShowSearchContent => !prevShowSearchContent);
    };

    /**
     * Toggles the visibility of accelerometer event markers on the map.
     */
    const toggleEventMarkers = () => {
        setShowEventMarkers(prevShowEventMarkers => !prevShowEventMarkers);
    };

    /**
     * Toggles the visibility of speeding event markers on the map.
     */
    const toggleSpeedingEventMarkers = () => {
        setShowSpeedingEventMarkers(prevShowSpeedingEventMarkers => !prevShowSpeedingEventMarkers);
    };

    /**
     * Handles the selection of a new map style from the dropdown menu.
     *
     * @param {string} newStyle - The Mapbox style URL to switch to.
     */
    const handleMapStyleChange = (newStyle) => {
        setSelectedMapStyle(newStyle);
    };

    // Render logic
    journeyData.forEach(function (journey) {
        if (journey.journeyScores.length === 0) {
            for (var x = 1; x < 9; x++) {
                journey.journeyScores.push({scoreType: x, score: 0});
            }
        }
    });
    let journeyScoresContent = null;
    if (activeJourney) {
        const activeJourneyData = journeyData.find(journey => journey.journeyId === activeJourney);
        if (activeJourneyData) {
            const journeyScores = activeJourneyData.journeyScores;

            journeyScoresContent = (
                <JourneyScores journeyScores={journeyScores} desiredScoreTypes={[1, 2, 3, 4, 5]}/>
            );
        }
    }

    /**
     * Determines the main content to display based on the loading and error states.
     */
    let content;

    if ((loading && journeyData.length === 0) || speedUnit === null) {
        content = <Loading/>;
    } else if (error) {
        content = <p>There was an error.</p>;
    } else if (journeyData.length === 0) {
        content = <p>No journeys reported for unit.</p>;
    } else {
        content = (
            <JourneyTable
                journeys={journeyData}
                handleTableRowClick={handleTableRowClick}
                activeJourney={activeJourney}
                speedUnit={speedUnit}
            />
        );
    }


    return (
        <div className="webcontainer">
            <Container fluid>
                <Row>
                    <Col md={2} lg={2} className="backbuttonlogoholder-journeylist">
                        <BackButtonLogoHolder backlink={'/deviceOverview/' + vehicleReference}/>
                    </Col>
                    <Col md={8} lg={8} className="fixed-col2" style={{marginBottom: '10px', marginTop: '10px'}}>
                        <div className="contentvehicle3" style={{marginTop: '0px'}}>
                            <h4>Vehicle: {vehicleReference}</h4>
                        </div>
                    </Col>

                </Row>
                <Row>
                    <Col md={5} lg={5}>
                        <div className="contentjourneylist">
                            <h4>Journeys</h4>
                            <Button variant={"secondary"} onClick={toggleSearchContent}
                                    style={{marginBottom: '10px'}}>
                                {showSearchContent ? 'Hide Search' : 'Show Search'}
                            </Button>
                            <Button variant={showPhantom ? "success" : "secondary"}
                                    style={{marginLeft: '10px', flex: '1', marginBottom: '10px'}}
                                    onClick={toggleIsPhantom}>
                                {'Include Invalid Journeys'}
                            </Button>
                            {showSearchContent && (
                                <JourneySearch
                                    startDate={startDate}
                                    endDate={endDate}
                                    setStartDate={setStartDate}
                                    setEndDate={setEndDate}
                                    searchForJourneys={searchForJourneys}
                                    clearSearch={clearSearch}
                                />
                            )
                            }
                            {content}
                            <div ref={observerRef} style={{height: '1px'}}></div>
                            {canLoadMore && loading && <Loading/>}

                        </div>
                    </Col>
                    <Col md={7} lg={7} className="fixed-col-journeylist">

                        {journeyScoresContent && (
                            <div style={{margin: '0px 0'}}>
                                {journeyScoresContent}
                            </div>
                        )}
                        <div className="fixed-col">
                            <div style={{display: 'flex', marginBottom: '0px'}}>
                                <div style={{zIndex: 10, position: 'relative'}}>
                                    <DropdownButton id="map-style-dropdown" title="Map Style" variant="primary">
                                        <Dropdown.Item
                                            onClick={() => handleMapStyleChange('mapbox://styles/mapbox/standard')}>
                                            Default
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            onClick={() => handleMapStyleChange('mapbox://styles/mapbox/satellite-streets-v12')}>
                                            Satellite Streets
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            onClick={() => handleMapStyleChange('mapbox://styles/mapbox/dark-v11')}>
                                            Dark
                                        </Dropdown.Item>
                                    </DropdownButton>
                                </div>
                                <Button style={{flex: '1'}}
                                        variant={showEventMarkers ? "success" : "danger"}
                                        onClick={toggleEventMarkers}>
                                    {'Events'}
                                </Button>
                                <Button style={{flex: '1'}}
                                        variant={showSpeedingEventMarkers ? "success" : "danger"}
                                        onClick={toggleSpeedingEventMarkers}>
                                    {'Speeding'}
                                </Button>
                                <Button style={{flex: '1'}}
                                        variant={usePitch ? "success" : "danger"}
                                        onClick={toggleIsPitch}>
                                    {'Pitch Angle'}
                                </Button>
                                <Button style={{flex: '1'}}
                                        variant={isAnimating ? "success" : "danger"}
                                        onClick={toggleAnimation}>
                                    {'Animate'}
                                </Button>
                                <Button style={{flex: '1'}}
                                        variant={mapMatched === false && isMapMatching === true ? "danger" : isMapMatching ? "success" : "danger"}
                                        onClick={toggleMapMatching}
                                        disabled={mapMatched === false && isMapMatching === true}>
                                    {'Map Matching'}
                                </Button>
                            </div>

                            <div id="mapContainer" ref={mapContainer}
                                 style={{width: '100%', height: '100%'}}></div>

                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );

}

export default JourneyList;