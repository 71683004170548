import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import './MoveDevice.css';
import BackButtonLogoHolder from "../../BackButtonLogoHolder/BackButtonLogoHolder";

/**
 * ErrorModal Component
 *
 * Displays a modal dialog with an error message.
 *
 * @param {Object} props - Component properties.
 * @param {boolean} props.show - Controls the visibility of the modal.
 * @param {string} props.errorMessage - The error message to display.
 * @param {Function} props.onHide - Function to call when the modal is closed.
 *
 * @returns {JSX.Element} The rendered modal component.
 */
const ErrorModal = ({ show, errorMessage, onHide }) => {
    return (
        <Modal show={show} onHide={onHide} centered>
            <Modal.Header closeButton>
                <Modal.Title>Error</Modal.Title>
            </Modal.Header>
            <Modal.Body>{errorMessage}</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

/**
 * DeviceRegistrationForm Component
 *
 * Renders a form to register a new device by providing vehicle reference,
 * device reference, and account reference. It includes form validation,
 * handles form submission to the API, and displays error messages using a modal.
 *
 * @param {Object} props - Component properties.
 * @param {string} props.userAccessToken - User's access token for API authorization.
 *
 * @returns {JSX.Element} The rendered component.
 */
const DeviceRegistrationForm = (props) => {
    // State to store the list of companies fetched from the API
    const [companies, setCompanies] = useState([]);

    // State to manage form data
    const [formData, setFormData] = useState({
        registerType: 'IC7',
        vehicleReference: '',
        deviceReference: '',
        accountReference: '',
    });

    // State to track which form fields have been touched (for validation purposes)
    const [touched, setTouched] = useState({
        vehicleReference: false,
        deviceReference: false,
        accountReference: false,
    });

    // State to manage error messages
    const [error, setError] = useState('');

    // State to control the visibility of the error modal
    const [showModal, setShowModal] = useState(false);

    // Hook to navigate programmatically
    const navigate = useNavigate();

    /**
     * Handles changes to form input fields.
     *
     * @param {Event} e - The input change event.
     */
    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    /**
     * Marks a form field as touched to trigger validation.
     *
     * @param {Event} e - The input blur event.
     */
    const handleBlur = (e) => {
        setTouched({
            ...touched,
            [e.target.name]: true
        });
    };

    /**
     * Validates if a form field has been touched and is not empty.
     *
     * @param {string} name - The name of the form field.
     * @returns {boolean} True if the field is valid, false otherwise.
     */
    const isValid = (name) => formData[name].trim() !== '' && touched[name];

    /**
     * Determines the CSS class for a form field based on its validation state.
     *
     * @param {string} name - The name of the form field.
     * @returns {string} The CSS class to apply.
     */
    const inputClass = (name) => {
        if (!touched[name]) return 'form-control';
        return isValid(name) ? 'form-control is-valid' : 'form-control is-invalid';
    };

    /**
     * useEffect Hook
     *
     * Fetches the list of companies from the API when the component mounts.
     */
    useEffect(() => {
        const fetchCompanies = async () => {
            try {
                const response = await fetch('/api/CompanyManagement/GetAllCompanies', {
                    headers: {
                        'Authorization': 'Bearer ' + props.userAccessToken
                    }
                });
                if (!response.ok) {
                    throw new Error('Failed to fetch companies');
                }
                const data = await response.json();
                setCompanies(data);
            } catch (error) {
                console.error('Error fetching companies:', error);
            }
        };

        fetchCompanies();
    }, [props.userAccessToken]);

    /**
     * Handles the form submission to register a new device.
     * Sends a POST request to the API with the form data.
     * On success, navigates to the vehicle management page.
     * On failure, displays an error modal with the error message.
     *
     * @async
     * @param {Event} e - The form submission event.
     */
    const handleSubmit = async (e) => {
        e.preventDefault();
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + props.userAccessToken
            },
            body: JSON.stringify({
                ...formData,
            })
        };

        try {
            const response = await fetch('api/Register/RegisterDevice', requestOptions);

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.errorMessage || `HTTP error! Status: ${response.status}`);
            }

            setFormData({
                vehicleReference: '',
                deviceReference: '',
                accountReference: '',
            });

            navigate('/vehicle-management');
        } catch (error) {
            console.error('Error during device registration:', error);
            setError(error.message);
            setShowModal(true);
        }
    };

    return (
        <div>
            {/* Error Modal */}
            <ErrorModal show={showModal} errorMessage={error} onHide={() => setShowModal(false)} />

            <div className="webcontainer">
                <Container fluid>
                    <Row>
                        <Col md={3} lg={3}>
                            {/* Back Button Component */}
                            <BackButtonLogoHolder backlink="/vehicle-management" />
                        </Col>
                        <Col md={6} lg={6} className="">
                            <div className="container mt-5">
                                {/* Registration Form */}
                                <form onSubmit={handleSubmit} className="form-container">
                                    <h2>Register IC7 Device</h2>
                                    <div className="mb-3">
                                        <label htmlFor="accountReference" className="form-label">
                                            Account Reference:
                                        </label>
                                        <input
                                            type="text"
                                            className={inputClass('accountReference')}
                                            name="accountReference"
                                            id="accountReference"
                                            value={formData.accountReference}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            placeholder="Enter Account Reference"
                                            required
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="vehicleReference" className="form-label">
                                            Vehicle Reference:
                                        </label>
                                        <input
                                            type="text"
                                            className={inputClass('vehicleReference')}
                                            name="vehicleReference"
                                            id="vehicleReference"
                                            value={formData.vehicleReference}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            placeholder="Enter Vehicle Reference"
                                            required
                                        />
                                    </div>

                                    <div className="mb-3">
                                        <label htmlFor="deviceReference" className="form-label">
                                            Device Reference:
                                        </label>
                                        <input
                                            type="text"
                                            className={inputClass('deviceReference')}
                                            name="deviceReference"
                                            id="deviceReference"
                                            value={formData.deviceReference}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            placeholder="Enter Device Reference"
                                            required
                                        />
                                    </div>

                                    {/* Submit Button */}
                                    <button type="submit" className="btn btn-primary">Register</button>
                                </form>
                            </div>
                        </Col>
                        <Col md={3} lg={3} className=""></Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
};

export default DeviceRegistrationForm;
