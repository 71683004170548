import React, { useState } from 'react';
import {Col, Container, Row, Modal, Button, Form} from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './MoveDevice.css';
import BackButtonLogoHolder from "../../BackButtonLogoHolder/BackButtonLogoHolder";

/**
 * ErrorModal Component
 *
 * Displays a modal dialog with an error message.
 *
 * @param {Object} props - Component properties.
 * @param {boolean} props.show - Controls the visibility of the modal.
 * @param {string} props.errorMessage - The error message to display.
 * @param {Function} props.onHide - Function to call when the modal is closed.
 *
 * @returns {JSX.Element} The rendered modal component.
 */
const ErrorModal = ({ show, errorMessage, onHide }) => {
    return (
        <Modal show={show} onHide={onHide} centered>
            <Modal.Header closeButton>
                <Modal.Title>Error</Modal.Title>
            </Modal.Header>
            <Modal.Body>{errorMessage}</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

/**
 * RegisterAppAccount Component
 *
 * Renders a form to register a new Cognito user by providing a username and password.
 * Handles form validation, submission to the API, and displays error messages using a modal.
 *
 * @param {Object} props - Component properties.
 * @param {string} props.userAccessToken - User's access token for API authorization.
 *
 * @returns {JSX.Element} The rendered component.
 */
const RegisterAppAccount = (props) => {
    const [formData, setFormData] = useState({
        cognitoUsername: '',
        cognitoPassword: '',
    });
    const [touched, setTouched] = useState({
        cognitoUsername: false,
        cognitoPassword: false,
    });
    const [error, setError] = useState('');
    const [showModal, setShowModal] = useState(false);

    const navigate = useNavigate();

    /**
     * Handles input changes and updates the form data state.
     *
     * @function handleChange
     * @param {Event} e - Input change event.
     */
    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    /**
     * Marks a form field as touched to trigger validation.
     *
     * @function handleBlur
     * @param {Event} e - Input blur event.
     */
    const handleBlur = (e) => {
        setTouched({
            ...touched,
            [e.target.name]: true
        });
    };

    /**
     * Validates if a form field has been touched and is not empty.
     *
     * @function isValid
     * @param {string} name - The name of the form field.
     * @returns {boolean} True if valid, false otherwise.
     */
    const isValid = (name) => formData[name].trim() !== '' && touched[name];

    /**
     * Determines the CSS class for a form field based on its validation state.
     *
     * @function inputClass
     * @param {string} name - The name of the form field.
     * @returns {string} The CSS class.
     */
    const inputClass = (name) => {
        if (!touched[name]) return 'form-control';
        return isValid(name) ? 'form-control is-valid' : 'form-control is-invalid';
    };

    /**
     * Handles the form submission to register a new Cognito user.
     * Sends a POST request to the API and handles success or error responses.
     *
     * @async
     * @function handleSubmit
     * @param {Event} e - Form submission event.
     */
    const handleSubmit = async (e) => {
        e.preventDefault();
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + props.userAccessToken
            },
            body: JSON.stringify(formData)
        };

        try {
            const response = await fetch('api/Register/RegisterCognitoUser', requestOptions);

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.errorMessage || `HTTP error! Status: ${response.status}`);
            }

            toast.success('User registered successfully');
            setFormData({
                cognitoUsername: '',
                cognitoPassword: '',
            });
            navigate('/vehicle-management');
        } catch (error) {
            console.error('Error during Cognito registration:', error);
            setError(error.message);
            setShowModal(true);
            toast.error(`Error during registration: ${error.message}`);
        }
    };

    return (
        <div>
            <ToastContainer position="top-right" autoClose={5000} />
            <ErrorModal show={showModal} errorMessage={error} onHide={() => setShowModal(false)} />

            <div className="webcontainer">
                <Container fluid>
                    <Row>
                        <Col md={3} lg={3}>
                            <BackButtonLogoHolder backlink="/vehicle-management" />
                        </Col>
                        <Col md={6} lg={6}>
                            <div className="container mt-5">
                                <Form onSubmit={handleSubmit} className="form-container">
                                    <h2>Register Mobile App Account</h2>
                                    <Form.Group className="mb-3" controlId="cognitoUsername">
                                        <Form.Label>New Email:</Form.Label>
                                        <Form.Control
                                            type="email"
                                            name="cognitoUsername"
                                            value={formData.cognitoUsername}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className={inputClass('cognitoUsername')}
                                            placeholder="Enter your email"
                                            required
                                        />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="cognitoPassword">
                                        <Form.Label>New Password:</Form.Label>
                                        <Form.Control
                                            type="password"
                                            name="cognitoPassword"
                                            value={formData.cognitoPassword}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className={inputClass('cognitoPassword')}
                                            placeholder="Enter your password"
                                            required
                                        />
                                    </Form.Group>
                                    <Button variant="primary" type="submit">
                                        Register
                                    </Button>
                                </Form>
                            </div>
                        </Col>
                        <Col md={3} lg={3}></Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
};

export default RegisterAppAccount;
