/**
 * Converts a given local date to a UTC date.
 *
 * @param {Date} date - The local date to convert.
 * @returns {Date|null} The UTC date or null if no date is provided.
 */
export const convertToUTC = (date) => {
    if (!date) return null;
    return new Date(Date.UTC(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes(),
        date.getSeconds()
    ));
};