import React, { useEffect } from 'react';
import "./Branding.css";

/**
 * Displays a message indicating the user is not a system administrator.
 *
 * @returns {JSX.Element} The not system admin component.
 */
const NotSystemAdmin = () => {
    useEffect(() => {
        document.title = "Not System Admin";
    }, []);

    return (
        <div className="unauthorised">
            <h2>You are not a system administrator</h2>
            <br />
            <h2>The page you are trying to view is only accessible to system admins</h2>
        </div>
    );
};

export default NotSystemAdmin;
