import React from 'react';
import { Button, Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker';

/**
 * JourneySearch Component
 *
 * Provides a form for users to search and filter journeys based on start and end dates.
 * Includes date pickers for selecting the desired date range and buttons to initiate
 * the search or clear the applied filters.
 *
 * @param {Object} props - Component properties.
 * @param {Date|null} props.startDate - The selected start date for filtering journeys.
 * @param {Date|null} props.endDate - The selected end date for filtering journeys.
 * @param {Function} props.setStartDate - Function to update the start date state.
 * @param {Function} props.setEndDate - Function to update the end date state.
 * @param {Function} props.searchForJourneys - Function to execute the search based on selected dates.
 * @param {Function} props.clearSearch - Function to reset the search filters.
 *
 * @returns {JSX.Element} The rendered JourneySearch component.
 */
const JourneySearch = ({ startDate, endDate, setStartDate, setEndDate, searchForJourneys, clearSearch }) => {
    return (
        <div className="contentsearch">
            <h4>Search</h4>
            <Form.Group>
                <p>Start Date:</p>
                <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    isClearable
                    showTimeSelect
                    dateFormat="Pp"
                />
                <p>End Date:</p>
                <DatePicker
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    isClearable
                    showTimeSelect
                    dateFormat="Pp"
                />
            </Form.Group>
            <Button variant="primary" onClick={searchForJourneys}>
                Search
            </Button>{' '}
            <Button variant="secondary" onClick={clearSearch}>
                Clear
            </Button>
        </div>
    );
};

export default JourneySearch;
