import React, { useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import './MoveDevice.css';

/**
 * DeviceMovementForm Component
 *
 * Renders a form to move a device from one vehicle/account to another.
 * Retrieves initial data from URL parameters, handles form submission
 * to move the device via API, and provides user feedback through toasts.
 *
 * @param {Object} props - Component properties.
 * @param {string} props.userAccessToken - User's access token for API authorization.
 *
 * @returns {JSX.Element} The rendered component.
 */
const DeviceMovementForm = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const query = new URLSearchParams(location.search);

    const deviceReference = query.get('deviceReference');
    const initialVehicleReference = query.get('vehicleReference') || '';
    const initialAccountReference = query.get('accountReference') || '';

    const [formData, setFormData] = useState({
        newVehicleReference: initialVehicleReference,
        newAccountReference: initialAccountReference,
        deviceReference: deviceReference
    });

    /**
     * Handles input changes and updates the form data state.
     *
     * @function handleChange
     * @param {Event} e - Input change event.
     */
    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    /**
     * Handles the form submission to move the device.
     * Sends a POST request to the API with the form data.
     * Provides user feedback through toast notifications and navigates upon success.
     *
     * @async
     * @function handleSubmit
     * @param {Event} e - Form submission event.
     */
    const handleSubmit = async (e) => {
        e.preventDefault();

        const moveDevicePayload = {
            newVehicleReference: formData.newVehicleReference,
            newAccountReference: formData.newAccountReference,
            deviceReference: formData.deviceReference
        };

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + props.userAccessToken
            },
            body: JSON.stringify(moveDevicePayload)
        };

        try {
            const response = await fetch('api/Register/MoveDevice', requestOptions);

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.errorMessage || `HTTP error! Status: ${response.status}`);
            }

            toast.success('Device moved successfully');
            setFormData({
                newVehicleReference: '',
                newAccountReference: '',
                deviceReference: ''
            });
            navigate('/vehicle-management');
        } catch (error) {
            console.error('Error during device movement:', error);
            toast.error(`Error during device movement: ${error.message}`);
        }
    };


    return (
        <div className="webcontainer">
            <ToastContainer position="top-right" autoClose={5000}/>
            <Container fluid>
                <Row>
                    <Col md={12} lg={12} className="fixed-col2">
                        <div className="contentvehicle3" style={{gridColumn: '1 / -1', textAlign: 'center'}}>
                            <h5>Move Device</h5>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={3} lg={3} className=""></Col>
                    <Col md={6}>
                        <div className="container mt-5">
                            <Form onSubmit={handleSubmit} className="form-container">
                                <Form.Group controlId="deviceReference">
                                    <Form.Label>Device Reference</Form.Label>
                                    <Form.Control
                                        type="text"
                                        readOnly
                                        value={formData.deviceReference}
                                    />
                                </Form.Group>

                                <Form.Group controlId="newVehicleReference">
                                    <Form.Label>New Vehicle Reference</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="newVehicleReference"
                                        value={formData.newVehicleReference}
                                        onChange={handleChange}
                                        required
                                        placeholder="Enter the New Vehicle Reference"
                                    />
                                </Form.Group>

                                <Form.Group controlId="newAccountReference">
                                    <Form.Label>New Account Reference</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="newAccountReference"
                                        value={formData.newAccountReference}
                                        onChange={handleChange}
                                        required
                                        placeholder="Enter the New Account Reference"
                                    />
                                </Form.Group>

                                <Button variant="primary" type="submit">
                                    Move Device
                                </Button>
                            </Form>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default DeviceMovementForm;
