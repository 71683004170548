import { getPastTwelveMonths } from "../DeviceOverview/DeviceOverviewUtils";

/**
 * Retrieves an array of monthly data values from a data object.
 *
 * @param {Object} dataObj - The data object containing monthly values.
 * @returns {number[]} An array of monthly data values.
 */
function getMonthlyData(dataObj) {
    return Array.from({ length: 12 }, (_, i) => dataObj?.[`month${11 - i}`] || 0);
}


/**
 * Retrieves an array of monthly data values from a data object.
 *
 * @param {Object} dataObj - The data object containing monthly values.
 * @param activeDevices - the active devices value.
 * @returns {number[]} An array of monthly data values.
 */
function getMonthlyDataPerDevice(dataObj, activeDevices) {
    return Array.from({ length: 12 }, (_, i) => {
        const monthKey = `month${11 - i}`;
        const rawValue = dataObj?.[monthKey] || 0;
        const devices = activeDevices?.[monthKey] || 1;
        return rawValue / devices;
    });
}

/**
 * Formats a dataset object for chart configuration.
 *
 * @param {Object} params - Parameters for formatting the dataset.
 * @param {string} params.label - The label for the dataset.
 * @param {number[]} params.data - The data array for the dataset.
 * @param {string} params.backgroundColor - Background color for the dataset.
 * @param {string} [params.borderColor] - Border color for the dataset.
 * @param {number} [params.borderWidth=1] - Border width for the dataset.
 * @param {boolean} [params.fill=false] - Whether to fill the area under the line (for line charts).
 * @returns {Object} The formatted dataset object.
 */
function formatDataset({ label, data, backgroundColor, borderColor = backgroundColor, borderWidth = 1, fill = false }) {
    return {
        label,
        data,
        backgroundColor,
        borderColor,
        borderWidth,
        fill,
    };
}

/**
 * Calculates the maximum Y-axis value for a chart, adjusted if all values are zero.
 *
 * @param {number[]} data - The data array to evaluate.
 * @returns {number} The maximum Y-axis value.
 */
function getMaxYValue(data) {
    const allValuesAreZero = data.every(val => val === 0);
    return allValuesAreZero ? 1 : Math.max(...data) * 1.2;
}

/**
 * Creates the chart data and options for the Journey Count Bar chart.
 *
 * @param {Object} JourneyCountData - The journey count data object containing monthly values.
 * @returns {Object} An object containing `journeyCountBar` data and `journeyBarOptions`.
 */
export function createJourneyCountBar(JourneyCountData) {
    const labels = getPastTwelveMonths();
    const data = getMonthlyData(JourneyCountData);
    const maxYValue = getMaxYValue(data);

    const journeyCountBar = {
        labels,
        datasets: [
            formatDataset({
                label: 'Journey Count Data',
                data,
                backgroundColor: '#0f8dff',
                borderWidth: 1,
                hoverBackgroundColor: 'rgb(232,4,183)',
                hoverBorderColor: 'rgba(75,192,192,1)',
            }),
        ],
    };

    let delayed;
    const journeyBarOptions = {
        plugins: {
            datalabels: {
                display: true,
                color: 'white',
                align: 'end',
                anchor: 'end',
                clamp: true,
                formatter: value => (value === 0 ? '' : Math.round(value)),
            },
            legend: { display: false },
            tooltip: {
                enabled: true,
                callbacks: {
                    label: context => `${context.parsed.y.toFixed(0)} journeys`,
                },
            },
        },
        scales: {
            y: { max: maxYValue, display: false },
            x: {
                ticks: { font: { size: 12 }, color: 'white' },
            },
        },
        animation: {
            onComplete: () => (delayed = true),
            delay: context => {
                let delay = 0;
                if (context.type === 'data' && context.mode === 'default' && !delayed) {
                    delay = context.dataIndex * 10 + context.datasetIndex * 10;
                }
                return delay;
            },
        },
    };

    return { journeyCountBar, journeyBarOptions };
}

/**
 * Creates the chart data and options for the Journey Duration Bar chart.
 *
 * @param {Object} JourneyDurationData - The journey duration data object containing monthly values.
 * @returns {Object} An object containing `journeyDurationBar` data and `journeyDurationOptions`.
 */
export function createJourneyDurationBar(JourneyDurationData) {
    const labels = getPastTwelveMonths();
    const data = getMonthlyData(JourneyDurationData);
    const maxYValue = getMaxYValue(data);

    const journeyDurationBar = {
        labels,
        datasets: [
            formatDataset({
                label: 'Journey Duration Data',
                data,
                backgroundColor: '#05b053',
                hoverBackgroundColor: 'rgb(232,4,183)',
                hoverBorderColor: 'rgba(75,192,192,1)',
            }),
        ],
    };

    let delayed;
    const journeyDurationOptions = {
        plugins: {
            datalabels: {
                display: true,
                color: 'white',
                align: 'end',
                anchor: 'end',
                formatter: value => {
                    if (value === 0) return '';
                    if (value >= 3600) return `${Math.floor(value / 3600)}h`;
                    return `${Math.floor(value / 60)}m`;
                },
            },
            tooltip: {
                enabled: true,
                callbacks: {
                    label: context => `${(context.parsed.y / 60).toFixed(0)} minutes`,
                },
            },
            legend: { display: false },
        },
        scales: {
            y: { beginAtZero: true, max: maxYValue, display: false },
            x: { ticks: { color: 'white' } },
        },
        animation: {
            onComplete: () => (delayed = true),
            delay: context => {
                let delay = 0;
                if (context.type === 'data' && context.mode === 'default' && !delayed) {
                    delay = context.dataIndex * 10 + context.datasetIndex * 10;
                }
                return delay;
            },
        },
    };

    return { journeyDurationBar, journeyDurationOptions };
}

/**
 * Creates the chart data and options for the Journey Distance Bar chart.
 *
 * @param {Object} JourneyDistanceData - The journey distance data object containing monthly values.
 * @param {Object|null} ActiveDevices - The active devices data per month (optional).
 * @param {number} speedUnit - The speed unit (0 for miles, 1 for kilometers).
 * @returns {Object} An object containing `journeyDistanceBar` data and `journeyDistanceOptions`.
 */
export function createJourneyDistanceBar(JourneyDistanceData, ActiveDevices = null, speedUnit) {
    const labels = getPastTwelveMonths();
    const conversionFactor = speedUnit === 0 ? 0.621371 : 1;
    const rawData = getMonthlyDataPerDevice(JourneyDistanceData, ActiveDevices);
    const data = rawData.map(value => value * conversionFactor);
    const maxYValue = getMaxYValue(data);

    const journeyDistanceBar = {
        labels,
        datasets: [
            formatDataset({
                label: 'Journey Distance',
                data,
                backgroundColor: '#e66c37',
                hoverBackgroundColor: '#e66c37',
                hoverBorderColor: '#e66c37',
            }),
        ],
    };

    const journeyDistanceOptions = {
        plugins: {
            datalabels: {
                display: true,
                color: 'white',
                align: 'end',
                anchor: 'end',
                formatter: value => {
                    if (value === 0) return '';
                    if (value >= 1000) return `${(value / 1000).toFixed(0)}k`;
                    return Math.round(value);
                },
            },
            legend: { display: false },
            tooltip: {
                enabled: true,
                callbacks: {
                    label: context => {
                        const unit = speedUnit === 0 ? 'miles' : 'km';
                        return `${context.parsed.y.toFixed(1)} ${unit}`;
                    },
                },
            },
        },
        scales: {
            y: { max: maxYValue, display: false },
            x: { ticks: { color: 'white' } },
        },
    };

    return { journeyDistanceBar, journeyDistanceOptions };
}

/**
 * Creates the chart data and options for the Journey Scores Line chart.
 *
 * @param {Object[]} JourneyScoresLineData - An array of journey scores data objects.
 * @returns {Object} An object containing `journeyScoresLineChart` data and `journeyScoresLineChartOptions`.
 */
export function createJourneyScoresLineChart(JourneyScoresLineData) {
    const labels = getPastTwelveMonths();
    const analysisTypeLabels = {
        3: 'Overall',
        4: 'Acceleration',
        5: 'Braking',
        6: 'Cornering',
        7: 'Speeding',
    };
    const colors = ['rgba(75,192,192,1)', 'rgba(255,99,132,1)', 'rgba(54,162,235,1)', 'rgba(255,206,86,1)', 'rgba(153,102,255,1)'];

    const datasets = JourneyScoresLineData.map((data, index) => {
        const borderWidth = data.analysisTypesId === 3 ? 3 : 1;
        return {
            label: analysisTypeLabels[data.analysisTypesId],
            data: getMonthlyData(data),
            fill: false,
            borderColor: colors[index % colors.length],
            borderWidth,
            pointBackgroundColor: colors[index % colors.length],
            pointBorderColor: colors[index % colors.length],
            pointRadius: 5,
            pointHoverRadius: 7,
        };
    });

    const journeyScoresLineChart = { labels, datasets };

    const journeyScoresLineChartOptions = {
        scales: {
            y: { beginAtZero: true, max: 100 },
            x: { ticks: { color: 'white' } },
        },
        plugins: {
            legend: {
                display: true,
                labels: { color: 'white', usePointStyle: true },
            },
        },
    };

    return { journeyScoresLineChart, journeyScoresLineChartOptions };
}

/**
 * Creates the chart data and options for the Journey Percentages Bar chart.
 *
 * @param {Object[]} journeyPercentagesData - An array of journey percentages data objects.
 * @param {Object} [ActiveDevicesData={}] - The active devices data per month.
 * @returns {Object} An object containing `journeyPercentagesBarChart` data and `journeyPercentagesBarChartOptions`.
 */
export function createJourneyPercentagesBarChart(journeyPercentagesData, ActiveDevicesData = {}) {
    const labels = getPastTwelveMonths();
    const analysisTypeLabels = {
        9: '0 - 30 mins',
        10: '30mins - 1 hour',
        11: '1+ hour',
    };
    const colors = ['rgba(75,192,192,1)', 'rgb(6,90,238)', 'rgb(120,54,235)'];
    const totalJourneysPerMonth = Array(12).fill(0);
    const dataByAnalysisType = {};
    
    Object.keys(analysisTypeLabels).forEach(analysisTypeId => {
        const dataObj = journeyPercentagesData.find(item => item.analysisTypesId === parseInt(analysisTypeId, 10));
        const data = getMonthlyData(dataObj);
        dataByAnalysisType[analysisTypeId] = data;
        data.forEach((value, idx) => {
            totalJourneysPerMonth[idx] += value;
        });
    });
    
    const datasets = Object.keys(analysisTypeLabels).map((analysisTypeId, index) => {
        const data = dataByAnalysisType[analysisTypeId].map((value, idx) => {
            const total = totalJourneysPerMonth[idx] || 1;
            return (value / total) * 100;
        });
        return formatDataset({
            label: analysisTypeLabels[analysisTypeId],
            data,
            backgroundColor: colors[index % colors.length],
            borderWidth: 1,
        });
    });

    const journeyPercentagesBarChart = { labels, datasets };

    const journeyPercentagesBarChartOptions = {
        plugins: {
            legend: { display: true },
            datalabels: { display: false },
            tooltip: {
                callbacks: {
                    label: context => `${context.raw?.toFixed(0) || 0}%`,
                },
            },
        },
        scales: {
            x: {
                stacked: true,
                ticks: { color: 'white' },
            },
            y: {
                stacked: true,
                max: 100,
                display: false,
            },
        },
    };

    return { journeyPercentagesBarChart, journeyPercentagesBarChartOptions };
}

/**
 * Creates the chart data and options for the Speeding Average Line chart.
 *
 * @param {Object} SpeedingAverage - The speeding average data object containing monthly values.
 * @returns {Object} An object containing `speedingLineChart` data and `speedingLineChartOptions`.
 */
export function createSpeedingAverageLineChart(SpeedingAverage) {
    const labels = getPastTwelveMonths();
    const color = 'rgba(224,25,31,0.7)';
    const dataPoints = getMonthlyData(SpeedingAverage).map(value => {
        let speedValue = value !== 0 ? value * 100 - 100 : 0;
        return speedValue < 0 ? 0 : speedValue;
    });
    const maxYValue = getMaxYValue(dataPoints);

    const speedingLineChart = {
        labels,
        datasets: [
            formatDataset({
                label: 'Speeding',
                data: dataPoints,
                backgroundColor: color,
                borderColor: color,
                fill: true,
            }),
        ],
    };

    const speedingLineChartOptions = {
        scales: {
            y: { max: maxYValue, display: false },
            x: { ticks: { color: 'white' } },
        },
        plugins: {
            tooltip: { enabled: false },
            legend: { display: false },
            datalabels: {
                display: true,
                color: 'white',
                anchor: 'end',
                align: 'top',
                formatter: value => (value === 0 ? '' : `${value.toFixed(0)}%`),
            },
        },
    };

    return { speedingLineChart, speedingLineChartOptions };
}