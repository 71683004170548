import React, { useCallback, useEffect, useState } from 'react';
import GetHttpConfig from "../../Helpers/GetHttpConfig";
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { Paging } from '../../Helpers/Paging';
import Loading from "../../Common/Loading";
import { Link, useLocation, useNavigate } from 'react-router-dom';

/**
 * UserGroup Component
 *
 * Manages the display and management of users within a specific group.
 * Allows searching for users by username, displaying user lists with pagination,
 * and removing users from the group.
 *
 * @param {Object} props - Component properties.
 * @param {string} props.userAccessToken - User's access token for API authorization.
 *
 * @returns {JSX.Element} The rendered component.
 */
const UserGroup = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const urlParam = location.search.substring(1);
    const groupName = decodeURIComponent(urlParam).replace(/%20/g, " ");

    const [UserGroupsData, setUserGroupsData] = useState([]);
    const [username, setUsername] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [pageSize, setPageSize] = useState(10);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalResults, setTotalResults] = useState(0);
    const [numberOfPages, setNumberOfPages] = useState(1);

    /**
     * useEffect Hook
     *
     * Sets the document title and fetches user groups when dependencies change.
     */
    useEffect(() => {
        document.title = "UBI User Group Management";
        getUserGroups(pageSize, pageNumber, groupName);
    }, [pageSize, pageNumber, groupName]);

    /**
     * Fetches user groups from the API based on group name and username with pagination.
     *
     * @async
     * @function getUserGroups
     * @param {number} pageSize - Number of users per page.
     * @param {number} pageNumber - Current page number.
     * @param {string} groupName - Name of the group to fetch users from.
     * @throws Will set error state if the fetch fails.
     */
    const getUserGroups = useCallback(async (pageSize, pageNumber, groupName) => {
        try {
            const response = await fetch(
                `/api/GroupManagement/GetUserGroups?GroupName=${encodeURIComponent(groupName)}&Username=${encodeURIComponent(username)}&PagingRequest.PageSize=${pageSize}&PagingRequest.PageNumber=${pageNumber}`,
                GetHttpConfig(props.userAccessToken)
            );
            if (!response.ok) {
                throw new Error("Failed to fetch user groups");
            }
            const data = await response.json();
            setUserGroupsData(data.results);
            setLoading(false);
            setError(false);
            setTotalResults(data.pageDetail.totalResults);
            setNumberOfPages(data.pageDetail.numberOfPages);
            setPageNumber(data.pageDetail.pageNumber);
        } catch (error) {
            console.error(error);
            setUserGroupsData([]);
            setLoading(false);
            setError(true);
            setTotalResults(0);
            setNumberOfPages(1);
            setPageNumber(1);
        }
    }, [username, props.userAccessToken]);

    /**
     * Handles the search form submission to filter users by username.
     *
     * @function searchGroups
     * @param {Event} event - Form submission event.
     */
    const searchGroups = (event) => {
        event.preventDefault();
        setLoading(true);
        setTotalResults(0);
        setNumberOfPages(1);
        setPageNumber(1);
        getUserGroups(pageSize, 1, groupName);
    };

    /**
     * Clears the search input and reloads the user group list.
     *
     * @function clearSearch
     */
    const clearSearch = () => {
        setUsername('');
        setLoading(true);
        getUserGroups(pageSize, pageNumber, groupName);
    };

    /**
     * Updates the username state based on user input.
     *
     * @function handleUserNameChange
     * @param {Event} event - Input change event.
     */
    const handleUserNameChange = (event) => {
        setUsername(event.target.value);
    };

    /**
     * Handles the deletion of a user from the group after confirmation.
     *
     * @async
     * @function handleDeleteUserGroup
     * @param {number} userGroupId - ID of the user-group association to delete.
     * @param {string} username - Username of the user to remove.
     */
    const handleDeleteUserGroup = async (userGroupId, username) => {
        if (window.confirm(`Do you want to remove ${username} from this group?`)) {
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + props.userAccessToken
                },
                body: JSON.stringify({ userGroupId })
            };
            try {
                const response = await fetch('/api/GroupManagement/DeleteUserGroup', requestOptions);
                if (!response.ok) {
                    throw new Error("Failed to delete user group");
                }
                getUserGroups(pageSize, pageNumber, groupName);
            } catch (error) {
                console.error(error);
                // Optionally handle error display to the user here
            }
        }
    };

    let content = null;
    if (loading) {
        content = <Loading />;
    } else if (error) {
        content = <p>There was an error.</p>;
    } else if (UserGroupsData.length === 0) {
        content = <p>No user groups found.</p>;
    } else {
        content = (
            <div className="content-vehicle-list">
                <table className="table table-striped table-dark">
                    <thead>
                    <tr>
                        <th>Username</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {UserGroupsData.map(userGroup => (
                        <tr key={userGroup.userGroupId}>
                            <td>{userGroup.username}</td>
                            <td>
                                <Button
                                    variant="outline-danger"
                                    type="button"
                                    onClick={() => handleDeleteUserGroup(userGroup.userGroupId, userGroup.username)}>
                                    Delete
                                </Button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        );
    }

    return (
        <div className="webcontainer">
            <Container fluid>
                <Row>
                    <Col md={12} lg={12} className="fixed-col2">
                        <div className="contentvehicle3">
                            <h4>User Group Management: {groupName}</h4>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} lg={2}>
                        <div className="contentsearch mt-5">
                            <h5>Search</h5>
                            <Form onSubmit={searchGroups}>
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Control
                                        type="text"
                                        value={username}
                                        onChange={handleUserNameChange}
                                        placeholder="Enter a Username"
                                    />
                                </Form.Group>
                                <Button
                                    variant="primary"
                                    type="submit"
                                    className="mr-3">
                                    Search
                                </Button>
                                <Button
                                    variant="secondary"
                                    type="button"
                                    onClick={clearSearch}>
                                    Clear
                                </Button>
                            </Form>
                        </div>
                        <div className="myvehicles-button">
                            <Link to={`/admin/addusergroup?${encodeURIComponent(groupName)}`}>
                                <Button variant="success" className="full-button">
                                    Add User To Group
                                </Button>
                            </Link>
                        </div>
                    </Col>
                    <Paging
                        content={content}
                        pageSize={pageSize}
                        pageNumber={pageNumber}
                        totalResults={totalResults}
                        numberOfPages={numberOfPages}
                        onChange={(newPage) => getUserGroups(pageSize, newPage, groupName)}
                        pageSizeChange={(newSize) => getUserGroups(newSize, 1, groupName)}
                    />
                </Row>
            </Container>
        </div>
    );
};

export default UserGroup;
