import React, { useEffect, useRef } from 'react';
import { Col } from 'react-bootstrap';
import './Paging.css';

/**
 * Infinite scrolling component that loads more data as the user scrolls down.
 *
 * @param {Object} props - Component properties.
 * @param {string} props.title - Title displayed at the top of the component.
 * @param {ReactNode} props.content - The content or data to display.
 * @param {number} props.pageSize - Number of items per page.
 * @param {number} props.pageNumber - Current page number.
 * @param {number} props.totalResults - Total number of results available.
 * @param {number} props.numberOfPages - Total number of pages.
 * @param {Function} props.onChange - Callback to load the next page of data.
 * @param {boolean} props.fetching - Flag indicating if data is being fetched.
 * @param {boolean} props.loadMore - Flag to enable infinite loading.
 *
 * @returns {JSX.Element} Infinite scrolling content area.
 */
export function PagingInfinite({
                                   title, 
                                   content, 
                                   pageSize, 
                                   pageNumber,
                                   totalResults, 
                                   numberOfPages, 
                                   onChange, 
                                   fetching,
                                   loadMore,
                       }) {
    const tableRef = useRef(null);

    useEffect(() => {
        if (tableRef.current && loadMore) {
            const observer = new IntersectionObserver(
                (entries) => {
                    const [entry] = entries;
                    if (entry.isIntersecting && pageNumber < numberOfPages && !fetching) {
                        onChange(pageNumber + 1);
                    }
                },
                { threshold: 1 }
            );
            observer.observe(tableRef.current);
            return () => observer.disconnect();
        }
    }, [tableRef, pageNumber, numberOfPages, fetching, loadMore, onChange]);

    const itemsLoaded = Math.min(pageNumber * pageSize, totalResults);
    const itemsRemaining = Math.max(totalResults - itemsLoaded, 0);

    return (
        <Col md={12} lg={9}>
            <div className="content">
                <h2>{title}</h2>
                {content}
                <div className="paginatin-custom"></div>
                {loadMore && pageNumber < numberOfPages && (
                    <div ref={tableRef}>
                        {fetching ? <p>Loading more data...</p> : null}
                    </div>
                )}
            </div>
        </Col>
    );
}
