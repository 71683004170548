import React, { useEffect, useRef, useState } from 'react';
import GetHttpConfig from "../Helpers/GetHttpConfig";
import { Button, Col, Container, Dropdown, DropdownButton, Form, Row } from 'react-bootstrap';
import Loading from "../Common/Loading";
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import 'mapbox-gl/dist/mapbox-gl.css';
import './VehicleLastLocation.css';
import moment from 'moment';
import markerBlue from '../images/hatchback.png';
import Moment from "react-moment";

/**
 * VehicleLastLocation component displays a map with the last known locations of vehicles.
 * It also provides a list of vehicles with their last activity details.
 *
 * @param {Object} props - Component properties.
 * @param {string} props.userAccessToken - User's access token for API requests.
 * @param {string} props.userEmail - User's email address.
 * @param {string} props.mapboxApiKey - Mapbox API Key.
 * @returns {JSX.Element} The rendered component.
 */
const VehicleLastLocation = ({ userAccessToken, userEmail, mapBoxApiKey }) => {
    document.title = "AGMT | Vehicle Map";
    
    const MAPBOX_STYLES = {
        STANDARD: 'mapbox://styles/mapbox/standard',
        SATELLITE_STREETS: 'mapbox://styles/mapbox/satellite-streets-v12',
        DARK: 'mapbox://styles/mapbox/dark-v11',
    };

    const [state, setState] = useState({
        LastActivityData: [],
        loading: true,
        error: false,
        pageSize: 30,
        pageNumber: 1,
        totalResults: 0,
        loadedResults: 0,
        numberOfPages: 1,
        vehicleReference: '',
        accountReference: '',
    });
    
    const [markersByVehicle, setMarkersByVehicle] = useState({});
    const mapContainer = useRef(null);
    const map = useRef(null);
    const markers = useRef([]);

    const [fetchingData, setFetchingData] = useState(false);
    const [vehicleReference, setVehicleReference] = useState('');
    const [accountReference, setAccountReference] = useState('');
    const [hoveredRow, setHoveredRow] = useState(null);
    const [isSearchVisible, setIsSearchVisible] = useState(true);
    const [refreshInterval, setRefreshInterval] = useState(null);
    const [intervalId, setIntervalId] = useState(null);
    const [timeLeft, setTimeLeft] = useState(null);
    const [totalElapsedTime, setTotalElapsedTime] = useState(0);
    const [selectedMapStyle, setSelectedMapStyle] = useState(MAPBOX_STYLES.STANDARD);

    // Initialize map on component mount
    useEffect(() => {
        const savedMapStyle = localStorage.getItem('selectedMapStyle') || MAPBOX_STYLES.STANDARD;
        setSelectedMapStyle(savedMapStyle);

        if (mapContainer.current && mapBoxApiKey) {
            initMap(savedMapStyle);
        }
    }, [mapBoxApiKey]);

    // Fetch vehicles when component mounts or filters change
    useEffect(() => {
        if (state.pageNumber === 1) {
            getVehicles(state.pageSize, state.pageNumber);
        }
    }, [state.vehicleReference, state.accountReference]);

    // Fetch vehicles when fetchingData state changes
    useEffect(() => {
        if (fetchingData) {
            getVehicles(state.pageSize, state.pageNumber);
            setFetchingData(false);
        }
    }, [fetchingData]);

    // Manage auto-refresh interval
    useEffect(() => {
        const handleInterval = () => {
            if (totalElapsedTime < 3600000) { // 1 hour max
                getVehicles(state.pageSize, state.pageNumber);
                setTimeLeft(refreshInterval);
                setTotalElapsedTime(prevTime => prevTime + refreshInterval);
            } else {
                clearInterval(intervalId);
                setTimeLeft(null);
            }
        };

        if (refreshInterval !== null) {
            if (intervalId) clearInterval(intervalId);

            setTimeLeft(refreshInterval);
            const newIntervalId = setInterval(handleInterval, refreshInterval);
            setIntervalId(newIntervalId);
        } else if (intervalId) {
            clearInterval(intervalId);
            setTimeLeft(null);
        }

        return () => clearInterval(intervalId);
    }, [refreshInterval, totalElapsedTime]);

    useEffect(() => {
        if (timeLeft > 0) {
            const timerId = setInterval(() => {
                setTimeLeft(prevTime => prevTime - 1000);
            }, 1000);
            return () => clearInterval(timerId);
        }
    }, [timeLeft]);

    /**
     * Initializes the Mapbox map.
     *
     * @param {string} initialStyle - The initial style to set for the map.
     */
    const initMap = (initialStyle) => {
        mapboxgl.accessToken = mapBoxApiKey;
        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: initialStyle,
            zoom: 1.5,
        });
        map.current.addControl(new mapboxgl.FullscreenControl());
        map.current.addControl(new mapboxgl.NavigationControl());
    };

    /**
     * Fetches vehicles data from the API and updates the map markers.
     *
     * @param {number} pageSize - The number of results per page.
     * @param {number} pageNumber - The current page number.
     */
    const getVehicles = async (pageSize, pageNumber) => {
        setState(prevState => ({
            ...prevState,
            loading: true,
            error: false,
        }));

        const url = `/api/LastLocation/GetLastLocation?PagingRequest.PageSize=${pageSize}&PagingRequest.PageNumber=${pageNumber}&VehicleReference=${state.vehicleReference}&AccountReference=${state.accountReference}`;
        try {
            const response = await fetch(url, GetHttpConfig(userAccessToken));
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();

            // Remove existing markers
            markers.current.forEach(marker => marker.remove());
            markers.current = [];
            setMarkersByVehicle({});

            setState(prevState => ({
                ...prevState,
                LastActivityData: data.results,
                error: false,
                loading: false,
                totalResults: data.pageDetail.totalResults,
                numberOfPages: data.pageDetail.numberOfPages,
                pageNumber: data.pageDetail.pageNumber,
                loadedResults: data.results.length,
            }));

            const newMarkersByVehicle = {};
            data.results.forEach(result => {
                if (
                    result.endLatitude >= -90 && result.endLatitude <= 90 &&
                    result.endLongitude >= -180 && result.endLongitude <= 180
                ) {
                    const lastSeen = moment(result.endDateTime).fromNow();
                    const el = createMarkerElement(result, lastSeen);
                    const marker = new mapboxgl.Marker(el)
                        .setLngLat([result.endLongitude, result.endLatitude])
                        .setPopup(createPopup(result))
                        .addTo(map.current);

                    newMarkersByVehicle[result.vehicleReference] = marker;
                    markers.current.push(marker);
                } else {
                    console.error('Invalid coordinates: ', result);
                }
            });
            setMarkersByVehicle(newMarkersByVehicle);
        } catch (error) {
            console.error(error);
            setState(prevState => ({
                ...prevState,
                LastActivityData: [],
                loading: false,
                error: true,
                totalResults: 0,
                numberOfPages: 1,
                pageNumber: 1,
            }));
        }
    };

    /**
     * Creates a DOM element for the map marker.
     *
     * @param {Object} result - The vehicle data.
     * @param {string} lastSeen - The time since last seen.
     * @returns {HTMLElement} The marker element.
     */
    const createMarkerElement = (result, lastSeen) => {
        const el = document.createElement('div');
        el.className = 'marker';

        const img = document.createElement('img');
        img.src = markerBlue;
        img.style.width = '30px';
        img.style.height = '30px';

        const p = document.createElement('p');
        p.innerHTML = result.vehicleReference;
        p.style.fontSize = '11px';
        p.style.textAlign = 'center';
        p.style.width = '150px';

        el.appendChild(p);
        el.appendChild(img);

        const tooltip = document.createElement('p');
        tooltip.className = 'marker-tooltip';
        tooltip.innerHTML = `${result.vehicleReference}<br/>Last Position: ${lastSeen}`;
        tooltip.style.textAlign = 'center';
        tooltip.style.width = '150px';
        tooltip.style.display = 'none';
        el.appendChild(tooltip);

        el.addEventListener('mouseenter', () => {
            tooltip.style.display = 'block';
        });

        el.addEventListener('mouseleave', () => {
            tooltip.style.display = 'none';
        });

        return el;
    };

    /**
     * Creates a Mapbox popup for the marker.
     *
     * @param {Object} result - The vehicle data.
     * @returns {mapboxgl.Popup} The Mapbox popup.
     */
    const createPopup = (result) => {
        return new mapboxgl.Popup({ offset: 50 }).setHTML(`
            <div class="dark-popup">
                <a href="/deviceOverview/${result.vehicleReference}">
                    <button>Overview</button>
                </a>
                <a href="/vehicles/${result.vehicleReference}">
                    <button>Journeys</button>
                </a>
            </div>
        `);
    };

    /**
     * Handles changes to the vehicle reference input.
     *
     * @param {React.ChangeEvent<HTMLInputElement>} event - The input change event.
     */
    const handleVehicleReferenceChange = (event) => {
        setVehicleReference(event.target.value);
    };

    /**
     * Handles changes to the account reference input.
     *
     * @param {React.ChangeEvent<HTMLInputElement>} event - The input change event.
     */
    const handleAccountReferenceChange = (event) => {
        setAccountReference(event.target.value);
    };

    /**
     * Toggles the visibility of the search form.
     */
    const toggleSearchVisibility = () => {
        setIsSearchVisible(!isSearchVisible);
    };

    /**
     * Handles the search form submission.
     *
     * @param {React.FormEvent<HTMLFormElement>} event - The form submission event.
     */
    const handleFilterSubmit = (event) => {
        event.preventDefault();
        clearMarkers();

        setState(prevState => ({
            ...prevState,
            pageNumber: 1,
            LastActivityData: [],
            loadedResults: 0,
            vehicleReference,
            accountReference,
        }));

        setFetchingData(true);
    };

    /**
     * Clears the search results and resets filters.
     *
     * @param {React.MouseEvent<HTMLButtonElement>} event - The button click event.
     */
    const clearResults = (event) => {
        event.preventDefault();
        clearMarkers();

        setVehicleReference('');
        setAccountReference('');

        setState(prevState => ({
            ...prevState,
            LastActivityData: [],
            loading: true,
            error: false,
            pageSize: 30,
            pageNumber: 1,
            totalResults: 0,
            loadedResults: 0,
            numberOfPages: 1,
            vehicleReference: '',
            accountReference: '',
        }));

        setFetchingData(true);
    };

    /**
     * Filters the vehicles by the user's email (account reference).
     */
    const filterByUserEmail = () => {
        clearMarkers();

        setAccountReference(userEmail);

        setState(prevState => ({
            ...prevState,
            pageNumber: 1,
            LastActivityData: [],
            loadedResults: 0,
            vehicleReference: '',
            accountReference: userEmail,
        }));

        setFetchingData(true);
    };

    /**
     * Clears all markers from the map.
     */
    const clearMarkers = () => {
        markers.current.forEach(marker => marker.remove());
        markers.current = [];
        setMarkersByVehicle({});
    };

    /**
     * Zooms the map to the selected vehicle's marker.
     *
     * @param {string} vehicleRef - The vehicle reference to zoom to.
     */
    const zoomToVehicle = (vehicleRef) => {
        const marker = markersByVehicle[vehicleRef];
        if (marker) {
            map.current.flyTo({ center: marker.getLngLat(), zoom: 14, pitch: 40 });
        }
    };

    /**
     * Checks if a date is within the last ten minutes.
     *
     * @param {string} date - The date string to check.
     * @returns {boolean} True if within the last ten minutes, else false.
     */
    const isInLastTenMinutes = (date) => {
        const TEN_MINUTES = 600000;
        const now = new Date();
        const lastUpdatedDate = new Date(date);
        return (now - lastUpdatedDate) < TEN_MINUTES;
    };

    /**
     * Handles changes to the page size for pagination.
     *
     * @param {number} newPageSize - The new page size.
     */
    const handlePageSizeChange = (newPageSize) => {
        setState(prevState => ({
            ...prevState,
            pageSize: newPageSize,
            pageNumber: 1,
        }));
        setFetchingData(true);
    };

    /**
     * Handles changes to the auto-refresh interval.
     *
     * @param {number|null} interval - The new refresh interval in milliseconds.
     */
    const handleRefreshIntervalChange = (interval) => {
        setRefreshInterval(interval);
        setTotalElapsedTime(0);
    };

    /**
     * Generates the title for the refresh interval dropdown.
     *
     * @returns {string} The title displaying the time left or default text.
     */
    const refreshTitle = () => {
        if (timeLeft === null) {
            return "Refresh Interval";
        } else {
            const secondsLeft = Math.max(Math.round(timeLeft / 1000), 0);
            return `Refreshing in ${secondsLeft} sec`;
        }
    };

    /**
     * Handles changes to the map style.
     *
     * @param {string} newStyle - The new map style URL.
     */
    const handleMapStyleChange = (newStyle) => {
        if (map.current) {
            map.current.setStyle(newStyle);
            setSelectedMapStyle(newStyle);
            localStorage.setItem('selectedMapStyle', newStyle);
        }
    };

    let content = null;
    if (state.loading) {
        content = <Loading />;
    } else if (state.error) {
        content = <p>There was an error.</p>;
    } else if (state.LastActivityData.length === 0) {
        content = <p>No vehicles found.</p>;
    } else {
        content = null; // Content is rendered below in the JSX
    }

    return (
        <div className="webcontainer">
            <Container fluid>
                <Row>
                    <Col md={12} lg={12} className="fixed-col2">
                        <div className="contentvehicle3" style={{ textAlign: 'center' }}>
                            <h4>Vehicle Map</h4>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} lg={4} className="vehicle-list-table">
                        <div className="contentsearch">
                            <div className="tileCharts">
                                <Button
                                    type="button"
                                    variant="primary"
                                    onClick={filterByUserEmail}
                                    className="full-button"
                                >
                                    My Vehicles
                                </Button>
                                <Button onClick={toggleSearchVisibility} className="full-button">
                                    Show Search
                                </Button>
                                <DropdownButton
                                    id="dropdown-refresh-interval"
                                    title={refreshTitle()}
                                    variant={refreshInterval ? "success" : "primary"}
                                    className="full-width-dropdown"
                                >
                                    <Dropdown.Item onClick={() => handleRefreshIntervalChange(60000)}>
                                        1 Minute
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={() => handleRefreshIntervalChange(300000)}>
                                        5 Minutes
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={() => handleRefreshIntervalChange(600000)}>
                                        10 Minutes
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={() => handleRefreshIntervalChange(null)} variant="danger">
                                        Stop Auto Refresh
                                    </Dropdown.Item>
                                </DropdownButton>
                                <DropdownButton
                                    id="dropdown-page-size"
                                    className="full-width-dropdown"
                                    title={`${state.pageSize} Vehicles`}
                                    variant="primary"
                                >
                                    <Dropdown.Item onClick={() => handlePageSizeChange(30)}>
                                        30 Vehicles
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={() => handlePageSizeChange(60)}>
                                        60 Vehicles
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={() => handlePageSizeChange(100)}>
                                        100 Vehicles
                                    </Dropdown.Item>
                                </DropdownButton>
                            </div>
                            {!isSearchVisible && (
                                <Form onSubmit={handleFilterSubmit}>
                                    <Form.Group controlId="accountReference">
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter Account"
                                            onChange={handleAccountReferenceChange}
                                            value={accountReference}
                                        />
                                    </Form.Group>
                                    <Form.Group controlId="vehicleReference">
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter Vehicle"
                                            onChange={handleVehicleReferenceChange}
                                            value={vehicleReference}
                                        />
                                    </Form.Group>

                                    <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                                        <Button variant="primary" type="submit" className="full-button">
                                            Search
                                        </Button>

                                        <Button variant="secondary" onClick={clearResults} className="full-button">
                                            Clear
                                        </Button>
                                    </div>
                                </Form>
                            )}
                            <div className="chart-wrapper-3"></div>
                            <div className="table-responsive">
                                <table className="table table-striped table-dark">
                                    <thead>
                                    <tr>
                                        <th>Vehicle</th>
                                        <th>Last Journey End</th>
                                        <th>Last Journey Position</th>
                                        <th>In journey</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {state.LastActivityData.map((item, index) => (
                                        <tr
                                            key={index}
                                            onClick={() => zoomToVehicle(item.vehicleReference)}
                                            onMouseEnter={() => setHoveredRow(index)}
                                            onMouseLeave={() => setHoveredRow(null)}
                                            style={hoveredRow === index ? { backgroundColor: "#094f7e" } : {}}
                                        >
                                            <td>{item.vehicleReference}</td>
                                            <td>
                                                <Moment fromNow>{item.lastUpdatedDateTime}</Moment>
                                            </td>
                                            <td>
                                                <Moment fromNow>{item.endDateTime}</Moment>
                                            </td>
                                            <td
                                                style={{
                                                    color: isInLastTenMinutes(item.endDateTime) ? 'green' : 'red',
                                                }}
                                            >
                                                {isInLastTenMinutes(item.endDateTime) ? '\u2713' : '\u2716'}
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </Col>

                    <Col md={12} lg={8} className="map-full">
                        <div className="fixed-col-vehicle-view">
                            <div id="mapContainer" ref={mapContainer} style={{ width: '100%', height: '100%' }}>
                                <div style={{ zIndex: 10, position: 'absolute', top: 10, left: 10 }}>
                                    <DropdownButton id="map-style-dropdown" title="Map Style">
                                        <Dropdown.Item
                                            onClick={() =>
                                                handleMapStyleChange(MAPBOX_STYLES.STANDARD)
                                            }
                                        >
                                            Standard
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            onClick={() =>
                                                handleMapStyleChange(MAPBOX_STYLES.SATELLITE_STREETS)
                                            }
                                        >
                                            Satellite Streets
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            onClick={() => handleMapStyleChange(MAPBOX_STYLES.DARK)}
                                        >
                                            Dark
                                        </Dropdown.Item>
                                    </DropdownButton>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default VehicleLastLocation;
