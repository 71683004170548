/**
 * Calculates the average scores for each analysis type over the past 12 months.
 *
 * @param {Array<Object>} journeyScoresLineData - An array of journey score data objects.
 * @returns {Object} An object mapping analysisTypeIds to their average scores.
 */
export function getAverageScores(journeyScoresLineData) {
    if (!Array.isArray(journeyScoresLineData) || journeyScoresLineData.length === 0) return {};

    const averages = {};

    journeyScoresLineData.forEach(journey => {
        const monthValues = Array.from({ length: 12 }, (_, i) => journey[`month${i}`] || 0);
        const validValues = monthValues.filter(value => typeof value === 'number' && !isNaN(value));
        const sum = validValues.reduce((acc, val) => acc + val, 0);
        const count = validValues.length;

        averages[journey.analysisTypesId] = count > 0 ? sum / count : 0;
    });

    return averages;
}

/**
 * Calculates the total sum of numerical month values in a data object.
 *
 * @param {string} dataKey - The key in the state object to retrieve the data.
 * @param {Object} state - The state object containing data.
 * @returns {number} The total sum of the month values.
 */
export function getTotal(dataKey, state) {
    const data = state[dataKey];
    if (!data || typeof data !== 'object') return 0;

    return Object.entries(data)
        .filter(([key, value]) => key.startsWith('month') && typeof value === 'number' && !isNaN(value))
        .reduce((total, [_, value]) => total + value, 0);
}

/**
 * Generates an array of the past twelve months in "MMM YY" format.
 *
 * @returns {string[]} An array of month labels.
 */
export function getPastTwelveMonths() {
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const date = new Date();

    return Array.from({ length: 12 }, () => {
        const monthLabel = `${monthNames[date.getMonth()]} ${date.getFullYear().toString().slice(-2)}`;
        date.setMonth(date.getMonth() - 1);
        return monthLabel;
    }).reverse();
}

/**
 * Ensures that all journey score data entries have month properties from month0 to month11.
 * Missing months are filled with a default value of 0.
 *
 * @param {Array<Object>} journeyScoresData - The array of journey scores data objects.
 * @returns {Array<Object>} The mapped journey scores data with defaulted month values.
 */
export function mapToLineData(journeyScoresData) {
    return journeyScoresData.map(item => {
        const mappedItem = { analysisTypesId: item.analysisTypesId };
        for (let i = 0; i < 12; i++) {
            mappedItem[`month${i}`] = item[`month${i}`] || 0;
        }
        return mappedItem;
    });
}
