import React, { useCallback, useEffect, useState } from 'react';
import GetHttpConfig from "../../Helpers/GetHttpConfig";
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { Paging } from '../../Helpers/Paging';
import Loading from "../../Common/Loading";
import { Link } from "react-router-dom";

/**
 * GroupManagement Component
 *
 * Manages the display, search, and deletion of groups. Fetches group data with pagination and handles user interactions for searching and deleting groups.
 *
 * @param {Object} props - Component properties.
 * @param {string} props.userAccessToken - User's access token for API authorization.
 *
 * @returns {JSX.Element} The rendered component.
 */
const GroupManagement = (props) => {
    document.title = "UBI Group Management";

    const [GroupsData, setGroupsData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [pageSize, setPageSize] = useState(10);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalResults, setTotalResults] = useState(0);
    const [numberOfPages, setNumberOfPages] = useState(1);
    const [groupName, setGroupName] = useState('');

    /**
     * Fetches groups from the API based on the current search criteria and pagination.
     *
     * @async
     * @function getGroups
     * @param {number} pageSize - Number of groups per page.
     * @param {number} pageNumber - Current page number.
     * @throws Will set error state if the fetch fails.
     */
    const getGroups = useCallback(async (pageSize, pageNumber) => {
        try {
            const response = await fetch(`/api/GroupManagement/GetGroups?GroupName=${encodeURIComponent(groupName)}&PagingRequest.PageSize=${pageSize}&PagingRequest.PageNumber=${pageNumber}`, GetHttpConfig(props.userAccessToken));
            if (!response.ok) {
                throw new Error("Failed to fetch groups");
            }
            const data = await response.json();
            setGroupsData(data.results);
            setLoading(false);
            setError(false);
            setTotalResults(data.pageDetail.totalResults);
            setNumberOfPages(data.pageDetail.numberOfPages);
            setPageNumber(data.pageDetail.pageNumber);
        } catch (error) {
            console.error(error);
            setGroupsData([]);
            setLoading(false);
            setError(true);
            setTotalResults(0);
            setNumberOfPages(1);
            setPageNumber(1);
        }
    }, [groupName, props.userAccessToken]);

    /**
     * useEffect Hook
     *
     * Fetches groups when the component mounts or when dependencies change.
     */
    useEffect(() => {
        getGroups(pageSize, pageNumber);
    }, [getGroups, pageSize, pageNumber]);

    /**
     * Handles the search form submission to filter groups by name.
     *
     * @function searchGroups
     * @param {Event} event - Form submission event.
     */
    const searchGroups = (event) => {
        event.preventDefault();
        setLoading(true);
        setTotalResults(0);
        setNumberOfPages(1);
        setPageNumber(1);
        getGroups(pageSize, 1);
    };

    /**
     * Clears the search input and reloads the group list.
     *
     * @function clearSearch
     */
    const clearSearch = () => {
        setGroupName('');
        setLoading(true);
        getGroups(pageSize, pageNumber);
    };

    /**
     * Updates the group name state based on user input.
     *
     * @function handleGroupNameChange
     * @param {Event} event - Input change event.
     */
    const handleGroupNameChange = (event) => {
        setGroupName(event.target.value);
    };

    /**
     * Handles the deletion of a group after user confirmation.
     *
     * @async
     * @function handleDeleteGroup
     * @param {string} groupName - Name of the group to delete.
     */
    const handleDeleteGroup = async (groupName) => {
        if (window.confirm(`Do you want to delete ${groupName}?`)) {
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + props.userAccessToken
                },
                body: JSON.stringify({ groupName })
            };
            try {
                const response = await fetch('/api/GroupManagement/DeleteGroup', requestOptions);
                if (!response.ok) {
                    throw new Error("Failed to delete group");
                }
                getGroups(pageSize, pageNumber);
            } catch (error) {
                console.error(error);
                // Optionally handle error display to the user here
            }
        }
    };

    let content = null;
    if (loading) {
        content = <Loading />;
    } else if (error) {
        content = <p>There was an error.</p>;
    } else if (GroupsData.length === 0) {
        content = <p>No group found.</p>;
    } else {
        content = (
            <div className="content-vehicle-list">
                <table className="table table-striped table-dark">
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th>Edit</th>
                        <th>Users</th>
                        <th>Vehicles</th>
                        <th>Delete</th>
                    </tr>
                    </thead>
                    <tbody>
                    {GroupsData.map(group => (
                        <tr key={group.groupId}>
                            <td>{group.groupName}</td>
                            <td>
                                <Link
                                    className="btn btn-outline-warning"
                                    to={`/admin/editgroup?${encodeURIComponent(group.groupName)}`}>
                                    Edit Group
                                </Link>
                            </td>
                            <td>
                                <Link
                                    className="btn btn-outline-primary"
                                    to={`/admin/usergroup?${encodeURIComponent(group.groupName)}`}>
                                    Users
                                </Link>
                            </td>
                            <td>
                                <Link
                                    className="btn btn-outline-primary"
                                    to={`/admin/vehiclegroup?${encodeURIComponent(group.groupName)}`}>
                                    Vehicles
                                </Link>
                            </td>
                            <td>
                                <Button
                                    className="btn btn-outline-danger"
                                    onClick={() => handleDeleteGroup(group.groupName)}>
                                    Delete
                                </Button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        );
    }

    return (
        <div className="webcontainer">
            <Container fluid>
                <Row>
                    <Col md={12} lg={12} className="fixed-col2">
                        <div className="contentvehicle3">
                            <h4>Group Management</h4>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} lg={2}>
                        <div className="contentsearch mt-5">
                            <h5>Search</h5>
                            <Form onSubmit={searchGroups}>
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Control
                                        type="text"
                                        value={groupName}
                                        onChange={handleGroupNameChange}
                                        placeholder="Enter a Group"
                                    />
                                </Form.Group>
                                <Button
                                    variant="primary"
                                    type="submit"
                                    className="mr-3">
                                    Search
                                </Button>
                                <Button
                                    variant="secondary"
                                    type="button"
                                    onClick={clearSearch}>
                                    Clear
                                </Button>
                            </Form>
                        </div>
                        <div className="myvehicles-button">
                            <Link to="/admin/addgroup">
                                <Button variant="success" className="full-button">
                                    Add Group
                                </Button>
                            </Link>
                        </div>
                    </Col>
                    <Paging
                        content={content}
                        pageSize={pageSize}
                        pageNumber={pageNumber}
                        totalResults={totalResults}
                        numberOfPages={numberOfPages}
                        onChange={(newPage) => getGroups(pageSize, newPage)}
                        pageSizeChange={(newSize) => getGroups(newSize, 1)}
                    />
                </Row>
            </Container>
        </div>
    );
};

export default GroupManagement;
