import React, { useEffect } from 'react';
import "./Branding.css";

/**
 * Displays an unauthorized access message and instructions to request access.
 *
 * @returns {JSX.Element} The unauthorized access component.
 */
const RequestAccess = () => {
    useEffect(() => {
        document.title = "Request Access";
    }, []);

    return (
        <div className="unauthorised">
            <h2>You are not authorised to access UBI Dashboard</h2>
            <br />
            <h2>Please request access from your system administrator</h2>
            <h2>Or contact info@agmtechnologies.co.uk</h2>
        </div>
    );
};

export default RequestAccess;
