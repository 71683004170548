import React, { useEffect, useState } from 'react';
import { useSortBy, useTable } from 'react-table';
import GetHttpConfig from "../Helpers/GetHttpConfig";
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import Loading from "../Common/Loading";
import './VehicleList.css';
import Moment from "react-moment";
import { useNavigate } from "react-router";
import { PagingInfiniteLarge } from "../Helpers/PagingInfiniteLarge";
import { throttle } from 'lodash';

/**
 * VehicleList component displays a list of vehicles.
 *
 * @param {Object} props - The component props.
 * @param {string} props.userAccessToken - The user's access token.
 * @param {string} props.userEmail - The user's email.
 * @returns {JSX.Element} The rendered component.
 */
export const VehicleList = ({ userAccessToken, userEmail }) => {
    document.title = "AGMT | Vehicles";

    // State variables
    const [vehicleData, setVehicleData] = useState([]);
    const [vehicleRefs, setVehicleRefs] = useState(new Set());
    const [pagesFetched, setPagesFetched] = useState(new Set());
    const [pagesFetching, setPagesFetching] = useState(new Set());
    const [pageSize, setPageSize] = useState(30);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalResults, setTotalResults] = useState(0);
    const [numberOfPages, setNumberOfPages] = useState(1);
    const [fetching, setFetching] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [queryParam, setQueryParam] = useState('');
    const [isMyVehiclesActive, setIsMyVehiclesActive] = useState(false);
    const [showAccountReference, setShowAccountReference] = useState(
        localStorage.getItem('showAccountReference') === 'true'
    );
    const [hoverIndex, setHoverIndex] = useState(-1);

    const navigate = useNavigate();

    // Define columns for react-table
    const columns = React.useMemo(
        () => [
            ...(showAccountReference ? [{ Header: 'Account', accessor: 'accountReference' }] : []),
            { Header: 'Vehicle', accessor: 'vehicleReference' },
            { Header: 'Device', accessor: 'deviceReference' },
            {
                Header: 'Provisioned',
                accessor: 'createdDateTime',
                Cell: ({ value }) => <Moment fromNow>{value}</Moment>,
            },
            {
                Header: 'Last Upload',
                accessor: 'lastPacketReceived',
                Cell: ({ value }) => <Moment fromNow>{value}</Moment>,
            },
        ],
        [showAccountReference]
    );

    // Save showAccountReference to localStorage whenever it changes
    useEffect(() => {
        localStorage.setItem('showAccountReference', showAccountReference.toString());
    }, [showAccountReference]);

    // Configure the table with columns and data
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
        {
            columns,
            data: vehicleData,
        },
        useSortBy
    );

    // Fetch vehicles when pageSize, pageNumber, or queryParam change
    useEffect(() => {
        throttledGetVehicles(pageSize, pageNumber, queryParam);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageSize, pageNumber, queryParam]);

    /**
     * Fetches vehicles from the API and updates the state.
     * Prevents duplicate results when scrolling quickly.
     *
     * @param {number} pageSize - The number of results per page.
     * @param {number} pageNumber - The current page number.
     * @param {string} queryParam - Additional query parameters for filtering.
     */
    const getVehicles = async (pageSize, pageNumber, queryParam) => {
        if (pagesFetched.has(pageNumber) || pagesFetching.has(pageNumber)) {
            // This page is already fetched or being fetched
            return;
        }

        setPagesFetching(prev => new Set(prev).add(pageNumber));
        setFetching(true);

        try {
            const response = await fetch(
                `/api/Vehicle/GetVehicles?PagingRequest.PageSize=${pageSize}&PagingRequest.PageNumber=${pageNumber}&${queryParam}&GetDeviceManagerData=false`,
                GetHttpConfig(userAccessToken)
            );
            const data = await response.json();

            setVehicleData(prevData => {
                const newVehicles = data.results.filter(vehicle => !vehicleRefs.has(vehicle.vehicleReference));

                setVehicleRefs(prevRefs => {
                    const updatedRefs = new Set(prevRefs);
                    newVehicles.forEach(vehicle => updatedRefs.add(vehicle.vehicleReference));
                    return updatedRefs;
                });

                return [...prevData, ...newVehicles];
            });

            setTotalResults(data.pageDetail.totalResults);
            setNumberOfPages(data.pageDetail.numberOfPages);
            setPageNumber(data.pageDetail.pageNumber);

            setPagesFetched(prev => new Set(prev).add(pageNumber));
        } catch (error) {
            console.error('Fetch error:', error);
        } finally {
            setPagesFetching(prev => {
                const updatedSet = new Set(prev);
                updatedSet.delete(pageNumber);
                return updatedSet;
            });
            setFetching(false);
        }
    };

    // Throttle getVehicles to prevent rapid successive calls
    const throttledGetVehicles = throttle(getVehicles, 500);

    /**
     * Shows only the vehicles associated with the current user's email.
     */
    const showMyVehicles = () => {
        const newQueryParam = `accountReference=${encodeURIComponent(userEmail)}`;
        resetData();
        setQueryParam(newQueryParam);
        setIsMyVehiclesActive(true);
    };

    /**
     * Exits the "My Vehicles" view and shows all vehicles.
     */
    const exitMyVehicles = () => {
        resetData();
        setQueryParam('');
        setIsMyVehiclesActive(false);
    };

    /**
     * Resets data and state variables when applying new filters or searches.
     */
    const resetData = () => {
        setVehicleData([]);
        setVehicleRefs(new Set());
        setPagesFetched(new Set());
        setPagesFetching(new Set());
        setTotalResults(0);
        setNumberOfPages(1);
        setPageNumber(1);
    };

    /**
     * Searches for vehicles based on the search query.
     *
     * @param {React.FormEvent<HTMLFormElement>} event - The form submission event.
     */
    const searchVehicles = event => {
        event.preventDefault();
        resetData();
        setQueryParam(`vehicleReference=${encodeURIComponent(searchQuery)}`);
    };

    /**
     * Handles changes to the vehicle reference search input.
     *
     * @param {React.ChangeEvent<HTMLInputElement>} event - The input change event.
     */
    const handleVehicleReferenceChange = event => {
        setSearchQuery(event.target.value);
    };

    return (
        <div className="webcontainer">
            <Container fluid>
                <Row>
                    <Col md={12} lg={12} className="fixed-col2">
                        <div className="contentvehicle3">
                            <h4>Vehicles</h4>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} lg={2} className="fixed-col1">
                        <div className="myvehicles-button">
                            <Form onSubmit={searchVehicles}>
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Control
                                        type="text"
                                        value={searchQuery}
                                        onChange={handleVehicleReferenceChange}
                                        placeholder="Search Vehicles"
                                    />
                                </Form.Group>
                            </Form>
                        </div>
                    </Col>
                    <Col md={12} lg={2} className="fixed-col1">
                        <div className="myvehicles-button">
                            <Button
                                variant={isMyVehiclesActive ? "danger" : "primary"}
                                type="button"
                                className="full-button"
                                onClick={() =>
                                    isMyVehiclesActive ? exitMyVehicles() : showMyVehicles()
                                }
                            >
                                {isMyVehiclesActive ? 'Exit My Vehicles' : 'My Vehicles'}
                            </Button>
                        </div>
                    </Col>
                    <Col md={12} lg={2} className="fixed-col1">
                        <div className="myvehicles-button">
                            <Button
                                variant="primary"
                                type="button"
                                className="full-button"
                                onClick={() => setShowAccountReference(prev => !prev)}
                            >
                                {showAccountReference ? 'Hide Account' : 'Show Account'}
                            </Button>
                        </div>
                    </Col>
                    <PagingInfiniteLarge
                        content={
                            <div className="content-vehicle-list">
                                <div className="table-responsive">
                                    <table {...getTableProps()} className="table table-striped table-dark">
                                        <thead>
                                        {headerGroups.map(headerGroup => (
                                            <tr {...headerGroup.getHeaderGroupProps()}>
                                                {headerGroup.headers.map(column => (
                                                    <th
                                                        {...column.getHeaderProps(column.getSortByToggleProps())}
                                                        className="cell-padding"
                                                    >
                                                        {column.render('Header')}
                                                        <span>
                                                                {column.isSorted
                                                                    ? column.isSortedDesc
                                                                        ? ' 🔽'
                                                                        : ' 🔼'
                                                                    : ''}
                                                            </span>
                                                    </th>
                                                ))}
                                            </tr>
                                        ))}
                                        </thead>

                                        <tbody {...getTableBodyProps()}>
                                        {rows.map((row, i) => {
                                            prepareRow(row);
                                            return (
                                                <tr
                                                    key={row.id}
                                                    {...row.getRowProps()}
                                                    className={i === hoverIndex ? "stackable" : ""}
                                                    onClick={() =>
                                                        navigate('/deviceOverview/' + row.original.vehicleReference)
                                                    }
                                                    onMouseOver={() => setHoverIndex(i)}
                                                    onMouseOut={() => setHoverIndex(-1)}
                                                    style={
                                                        i === hoverIndex
                                                            ? {
                                                                backgroundColor: "#094f7e",
                                                                cursor: 'pointer',
                                                            }
                                                            : {}
                                                    }
                                                >
                                                    {row.cells.map(cell => (
                                                        <td {...cell.getCellProps()} className="stackable">
                                                            {cell.render('Cell')}
                                                        </td>
                                                    ))}
                                                </tr>
                                            );
                                        })}
                                        {fetching && (
                                            <tr>
                                                <td colSpan="100%">
                                                    <Loading />
                                                </td>
                                            </tr>
                                        )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        }
                        pageSize={pageSize}
                        pageNumber={pageNumber}
                        totalResults={totalResults}
                        numberOfPages={numberOfPages}
                        onChange={e => throttledGetVehicles(pageSize, e, queryParam)}
                        pageSizeChange={e => {
                            setPageSize(e);
                            resetData();
                        }}
                        pageSizeSet={e => setPageSize(e)}
                        fetching={fetching}
                        loadMore={searchQuery === ''}
                    />
                </Row>
            </Container>
        </div>
    );
};