import React, {useCallback, useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import GetHttpConfig from "../../Helpers/GetHttpConfig";
import {Button, Col, Container, Form, Row} from 'react-bootstrap';
import {Paging} from '../../Helpers/Paging';
import Loading from "../../Common/Loading";
import "../../Common/Branding.css";

/**
 * UserManagement Component
 * Handles the display and management of users, including fetching user data,
 * searching, pagination, and user deletion.
 *
 * @param {object} props - Component properties.
 * @returns {JSX.Element} The rendered component.
 */
const UserManagement = (props) => {
    document.title = "UBI User Management";

    const [UsersData, setUsersData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [pageSize, setPageSize] = useState(10);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalResults, setTotalResults] = useState(0);
    const [numberOfPages, setNumberOfPages] = useState(1);
    const [username, setUsername] = useState("");

    /**
     * Fetches the list of users based on the current username filter and pagination settings.
     *
     * @param {number} pageSize - Number of users per page.
     * @param {number} pageNumber - Current page number.
     */
    const getUsers = useCallback(async (pageSize, pageNumber) => {
        try {
            const response = await fetch(`/api/UserManagement/GetUsers?Username=${username}&PagingRequest.PageSize=${pageSize}&PagingRequest.PageNumber=${pageNumber}`, GetHttpConfig(props.userAccessToken));
            if (!response.ok) {
                throw new Error("Failed to fetch users");
            }
            const data = await response.json();
            setUsersData(data.results);
            setLoading(false);
            setError(false);
            setTotalResults(data.pageDetail.totalResults);
            setNumberOfPages(data.pageDetail.numberOfPages);
            setPageNumber(data.pageDetail.pageNumber);
        } catch (error) {
            setUsersData([]);
            setLoading(false);
            setError(true);
            setTotalResults(0);
            setNumberOfPages(1);
            setPageNumber(1);
        }
    }, [username, props.userAccessToken]);

    useEffect(() => {
        getUsers(pageSize, pageNumber);
    }, [getUsers, pageSize, pageNumber]);

    /**
     * Handles the search form submission to filter users by username.
     *
     * @param {Event} event - The form submission event.
     */
    const searchUsers = useCallback(async (event) => {
        event.preventDefault();
        setLoading(true);
        setTotalResults(null);
        setNumberOfPages(null);
        setPageNumber(null);
        await getUsers(pageSize, 1);
    }, [getUsers, pageSize]);

    /**
     * Clears the search filter and reloads the user list.
     */
    const clearSearch = useCallback(() => {
        setUsername('');
        getUsers(pageSize, pageNumber);
    }, [getUsers, pageSize, pageNumber]);

    /**
     * Updates the username state based on user input.
     *
     * @param {Event} event - The input change event.
     */
    const handleUsernameChange = (event) => {
        setUsername(event.target.value);
    };

    /**
     * Handles the deletion of a user after confirmation.
     *
     * @param {string} username - The username of the user to delete.
     */
    const handleDeleteUser = useCallback(async (username) => {
        if (window.confirm('Do you want to delete ' + username + '?')) {
            const requestOptions = {
                method: 'POST',
                headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + props.userAccessToken},
                body: JSON.stringify({username})
            };
            try {
                const response = await fetch('/api/UserManagement/DeleteUser', requestOptions);
                if (!response.ok) {
                    throw new Error("Failed to delete user");
                }
                await getUsers(pageSize, pageNumber);
            } catch (error) {
                console.error(error);
            }
        }
    }, [getUsers, pageSize, pageNumber, props.userAccessToken]);

    let content;
    if (loading) {
        content = <Loading/>;
    } else if (error) {
        content = <p>There was an error.</p>;
    } else if (UsersData.length === 0) {
        content = <p>No user found.</p>;
    } else {
        content = (
            <div className="content-vehicle-list">
                <table className="table table-striped table-dark">
                    <thead>
                    <tr>
                        <th>Username</th>
                        <th>Company</th>
                        <th>Unit Measurement</th>
                        <th></th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {UsersData.map(user => (
                        <tr key={user.userID}>
                            <td>{user.username}</td>
                            <td>{user.companyName}</td>
                            <td>{user.speedUnit === 0 ? 'Imperial' : 'Metric'}</td>
                            <td>
                                <Link to={"/admin/edituser?" + user.username} className="btn btn-outline-warning">Edit
                                    User</Link>
                            </td>
                            <td>
                                <Button variant="outline-secondary" className="btn btn-outline-danger"
                                        onClick={() => handleDeleteUser(user.username)}>Delete</Button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        );
    }

    return (
        <div className="webcontainer">
            <Container fluid>
                <Row>
                    <Col md={12} lg={12} className="fixed-col2">
                        <div className="contentvehicle3">
                            <h4>User Management</h4>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} lg={2}>
                        <div className="contentsearch mt-5">
                            <h4>Search</h4>
                            <Form onSubmit={searchUsers}>
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Control type="text" value={username} onChange={handleUsernameChange}
                                                  placeholder="Enter a Username"/>
                                </Form.Group>
                                <Button variant="primary" type="submit" className="mr-3">Search</Button>
                                <Button variant="secondary" onClick={clearSearch}>Clear</Button>
                            </Form>
                        </div>
                        <div className="myvehicles-button">
                            <Link to="/admin/adduser">
                                <Button variant="success" className="full-button" size="">Add User</Button>
                            </Link>
                        </div>
                    </Col>
                    <Paging
                        content={content}
                        pageSize={pageSize}
                        pageNumber={pageNumber}
                        totalResults={totalResults}
                        numberOfPages={numberOfPages}
                        onChange={page => getUsers(pageSize, page)}
                        pageSizeChange={size => getUsers(size, 1)}
                    />
                </Row>
            </Container>
        </div>
    );
};

export default UserManagement;
