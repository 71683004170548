import React from 'react';
import { FaArrowCircleLeft } from 'react-icons/fa';
import './BackButtonLogoHolder.css';
import { Button } from "react-bootstrap";
import { Link } from 'react-router-dom';

/**
 * Renders a back button that links to a specified URL.
 *
 * Props:
 * - backlink: URL to navigate back to; defaults to root ("/") if not provided.
 *
 * Returns:
 * - A button with a back arrow icon, linking to `backlink` or root by default.
 */
function BackButtonLogoHolder(props) {
    const { backlink } = props;
    const defaultLink = "/";

    return (
        <div className="left backbuttonlogoholder">
            <Link to={backlink || defaultLink}>
                <Button variant={"secondary"}>
                    <FaArrowCircleLeft color="white" /> Back
                </Button>
            </Link>
        </div>
    );
}

export default BackButtonLogoHolder;
