import React, { useState, useEffect, useCallback } from 'react';
import GetHttpConfig from "../../Helpers/GetHttpConfig";
import { Button, Form, Col, Container, Row } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';

/**
 * EditCompany Component
 *
 * Provides a form to edit an existing company's name and customer reference.
 * Fetches the company's current data based on the URL parameter and handles form submission to update the company.
 *
 * @param {Object} props - Component properties.
 * @param {string} props.userAccessToken - User's access token for API authorization.
 *
 * @returns {JSX.Element} The rendered component.
 */
const EditCompany = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const urlParam = location.search.substring(1);
    const name = decodeURIComponent(urlParam).replace(/%20/g, " ");

    const [NewCompanyData, setNewCompanyData] = useState([]);
    const [CompanyData, setCompanyData] = useState([]);
    const [companyName, setCompanyName] = useState("");
    const [customerReference, setCustomerReference] = useState("");

    /**
     * useEffect Hook
     *
     * Sets the document title and fetches the company data when the component mounts or when the company name changes.
     */
    useEffect(() => {
        document.title = "Edit Company";
        getCompany(name);
    }, [name]);

    /**
     * Fetches a single company's data from the API.
     *
     * @async
     * @function getCompany
     * @param {string} companyName - The name of the company to fetch.
     * @throws Will log an error if the fetch fails.
     */
    const getCompany = useCallback(async (companyName) => {
        try {
            const response = await fetch(`/api/CompanyManagement/GetSingleCompany?CompanyName=${companyName}`, GetHttpConfig(props.userAccessToken));
            if (!response.ok) {
                throw new Error("Failed to fetch company data");
            }
            const data = await response.json();
            setCompanyData(data);
            setCompanyName(data.name);
            setCustomerReference(data.customerReference);
        } catch (error) {
            console.error(error);
        }
    }, [props.userAccessToken]);

    /**
     * Handles the form submission to edit the company details.
     *
     * @async
     * @function handleEditCompany
     * @param {string} oldCompanyName - The original name of the company.
     * @param {string} newCompanyName - The updated name of the company.
     * @param {string} customerReference - The updated customer reference.
     * @param {Event} e - Form submission event.
     * @throws Will log an error if the POST request fails.
     */
    const handleEditCompany = useCallback(async (oldCompanyName, newCompanyName, customerReference, e) => {
        e.preventDefault();
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + props.userAccessToken
            },
            body: JSON.stringify({ oldName: oldCompanyName, name: newCompanyName, customerReference: customerReference })
        };
        try {
            const response = await fetch('/api/CompanyManagement/EditCompany', requestOptions);
            if (!response.ok) {
                throw new Error("Failed to edit company");
            }
            navigate("/admin/companymanagement");
        } catch (error) {
            console.error(error);
        }
    }, [props.userAccessToken, navigate]);

    return (
        <div className="webcontainer">
            <Container fluid>
                <Row>
                    <Col md={12} lg={12}>
                        <div className="contentvehicle3" style={{ textAlign: 'center' }}>
                            <h5>Edit Company - {name}</h5>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={3} lg={3}></Col>
                    <Col md={6}>
                        <div className="container mt-5">
                            <Form onSubmit={(e) => handleEditCompany(name, companyName, customerReference, e)} className="form-container">
                                <Form.Group controlId="companyEdit">
                                    <Form.Label>Company Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={companyName}
                                        placeholder="Enter the Company Name"
                                        onChange={(e) => setCompanyName(e.target.value)} />

                                    <br />

                                    <Form.Label>Customer Reference</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={customerReference}
                                        placeholder="Enter the Customer Reference"
                                        onChange={(e) => setCustomerReference(e.target.value)} />

                                    <br /><br />

                                    <Button variant="primary" type="submit">Edit Company</Button>
                                </Form.Group>
                            </Form>
                        </div>
                    </Col>
                    <Col md={3} lg={3}></Col>
                </Row>
            </Container>
        </div>
    );
};

export default EditCompany;
