/**
 * Retrieves the score for a specific score type from a list of scores.
 *
 * @param {string} scoreType - The type of score to find.
 * @param {Array} scores - Array of score objects, each with `scoreType` and `score` properties.
 * @returns {number|undefined} The score matching the `scoreType`, or `undefined` if not found.
 */
const GetScoreByType = (scoreType, scores) => {
    for (let i = 0; i < scores.length; i++) {
        const item = scores[i];
        if (item.scoreType === scoreType) {
            return item.score;
        }
    }
};

export default GetScoreByType;
