import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';

/**
 * EditGroup Component
 *
 * Renders a form to edit an existing group's name. Retrieves the current group name from the URL parameters and handles form submission to update the group via API.
 *
 * @param {Object} props - Component properties.
 * @param {string} props.userAccessToken - User's access token for API authorization.
 *
 * @returns {JSX.Element} The rendered component.
 */
const EditGroup = (props) => {
    const navigate = useNavigate();
    const location = useLocation();

    const urlParam = location.search.substring(1);
    const initialGroupName = decodeURIComponent(urlParam).replace(/%20/g, " ");

    const [GroupName, setGroupName] = useState(initialGroupName);
    const [groupName, setNewGroupName] = useState("");

    /**
     * useEffect Hook
     *
     * Sets the document title when the component mounts.
     */
    useEffect(() => {
        document.title = "Edit Group";
    }, []);

    /**
     * Handles the form submission to edit the group's name.
     *
     * @async
     * @function handleEditGroup
     * @param {Event} e - Form submission event.
     * @throws Will log an error if the POST request fails.
     */
    const handleEditGroup = async (e) => {
        e.preventDefault();
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + props.userAccessToken
            },
            body: JSON.stringify({ oldGroupName: GroupName, groupName })
        };
        try {
            const response = await fetch('/api/GroupManagement/EditGroup', requestOptions);
            if (!response.ok) {
                throw new Error("Failed to edit group");
            }
            navigate("/admin/groupmanagement");
        } catch (error) {
            console.error(error);
            // Optionally handle error display to the user here
        }
    };

    /**
     * Updates the new group name state based on user input.
     *
     * @function handleGroupNameChange
     * @param {Event} event - Input change event.
     */
    const handleGroupNameChange = (event) => {
        setNewGroupName(event.target.value);
    };

    return (
        <div className="webcontainer">
            <Container fluid>
                <Row>
                    <Col md={12} lg={12}>
                        <div className="contentvehicle3" style={{ textAlign: 'center' }}>
                            <h5>Edit Group</h5>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={3} lg={3}></Col>
                    <Col md={6}>
                        <div className="container mt-5">
                            <Form onSubmit={handleEditGroup} className="form-container">
                                <Form.Group controlId="groupEdit">
                                    <Form.Label>Edit group name: {GroupName}</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={groupName}
                                        placeholder="Enter the Group Name"
                                        onChange={handleGroupNameChange}
                                        required
                                    />
                                    <br /><br />
                                    <Button variant="primary" type="submit">Edit Group</Button>
                                </Form.Group>
                            </Form>
                        </div>
                    </Col>
                    <Col md={3} lg={3}></Col>
                </Row>
            </Container>
        </div>
    );
};

export default EditGroup;
