import React, { useState } from 'react';
import { Col } from 'react-bootstrap';
import Pagination from 'react-js-pagination';
import './Paging.css';

/**
 * Paging component handles pagination controls and display.
 *
 * @param {Object} props - Component properties.
 * @param {string} props.title - Title displayed at the top of the component.
 * @param {ReactNode} props.content - The content or data to display.
 * @param {number} props.pageSize - Number of items per page.
 * @param {number} props.pageNumber - Current page number.
 * @param {number} props.totalResults - Total number of results available.
 * @param {number} props.numberOfPages - Total number of pages.
 * @param {Function} props.onChange - Callback to change the page.
 * @param {Function} props.pageSizeChange - Callback when page size changes.
 * @param {Function} props.pageSizeSet - Callback to set the page size.
 *
 * @returns {JSX.Element} The rendered pagination component.
 */
export const Paging = (props) => {
    const [pageSize, setPageSize] = useState(props.pageSize);

    /**
     * Handles the change in items per page.
     *
     * @param {React.ChangeEvent<HTMLSelectElement>} e - The change event.
     */
    const onChangePerPage = (e) => {
        const newPageSize = parseFloat(e.target.value);
        props.pageSizeChange(newPageSize);
        props.pageSizeSet(newPageSize);
        setPageSize(newPageSize);
    };

    const shouldShowPagination = props.totalResults > 10;

    return (
        <Col md={12} lg={9}>
            <div className="content">
                <h2>{props.title}</h2>
                {props.content}

                {shouldShowPagination && (
                    <>
                        <div className="pagination-custom">
                            <Pagination
                                activePage={props.pageNumber}
                                itemsCountPerPage={pageSize}
                                totalItemsCount={props.totalResults}
                                onChange={props.onChange}
                            />
                        </div>
                        <div className="pagination-custom">
                            <label htmlFor="table-page-size">Items per Page:&nbsp;</label>
                            <select
                                value={props.pageSize}
                                onChange={onChangePerPage}
                                id="table-page-size"
                                name="table-page-size"
                            >
                                <option value="10">10</option>
                                <option value="15">15</option>
                                <option value="30">30</option>
                                <option value="50">50</option>
                            </select>
                        </div>
                        <div className="pagination-custom">
                            <label>Total pages: {props.numberOfPages}</label>
                        </div>
                    </>
                )}
            </div>
        </Col>
    );
};
