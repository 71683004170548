import React, { useState, useEffect, useCallback } from 'react';
import GetHttpConfig from "../Helpers/GetHttpConfig";
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import Loading from "../Common/Loading";
import Moment from 'react-moment';
import './CrashReports.css';
import {PagingInfinite} from "../Helpers/PagingInfinite";

/**
 * TamperReports Component
 *
 * Displays a list of tamper reports with search functionality and infinite scrolling pagination.
 *
 * @param {Object} props - Component properties.
 * @param {string} props.userAccessToken - Access token for authenticated API requests.
 *
 * @returns {JSX.Element} The rendered TamperReports component.
 */
const TamperReports = (props) => {
    const [TamperData, setTamperData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [pageSize, setPageSize] = useState(30);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalResults, setTotalResults] = useState(0);
    const [numberOfPages, setNumberOfPages] = useState(1);
    const [vehicleReference, setVehicleReference] = useState('');
    const [fetching, setFetching] = useState(false);
    const [moreToLoad, setMoreToLoad] = useState(true);

    /**
     * useEffect Hook - Initialization
     *
     * - Sets the document title.
     * - Fetches initial tamper data based on pageSize and pageNumber.
     *
     * @dependencies [pageSize, pageNumber]
     */
    useEffect(() => {
        document.title = "AGMT | Tamper";
        getTampers(pageSize, pageNumber);
    }, [pageSize, pageNumber]);

    /**
     * getTampers Function
     *
     * Fetches tamper reports from the API based on the provided page size and page number.
     *
     * @param {number} pageSize - Number of results per page.
     * @param {number} pageNumber - The page number to fetch.
     *
     * @returns {Promise<void>} A promise that resolves when the data is fetched and state is updated.
     */
    const getTampers = useCallback(async (pageSize, pageNumber) => {
        setFetching(true);

        try {
            const response = await fetch(`/api/Accelerometer/GetTampers?VehicleReference=${vehicleReference}&PagingRequest.PageSize=${pageSize}&PagingRequest.PageNumber=${pageNumber}`, GetHttpConfig(props.userAccessToken));
            const data = await response.json();
            setTamperData(pageNumber === 1 ? data.results : [...TamperData, ...data.results]);
            setError(false);
            setLoading(false);
            setMoreToLoad(data.pageDetail.hasMoreData)
            setTotalResults(data.pageDetail.totalResults);
            setNumberOfPages(data.pageDetail.numberOfPages);
            setPageNumber(data.pageDetail.pageNumber);
        } catch (error) {
            console.error(error);
            setError(true);
            setLoading(false);
            setTamperData([]);
            setTotalResults(0);
            setNumberOfPages(1);
            setPageNumber(1);
        } finally {
            setFetching(false);
        }
    }, [vehicleReference, props.userAccessToken, TamperData]);

    /**
     * searchCrashes Function
     *
     * Handles the search form submission by resetting relevant state variables and fetching new tamper data.
     *
     * @param {Object} event - The form submission event.
     */
    const searchCrashes = (event) => {
        event.preventDefault();
        setLoading(true);
        setTotalResults(null);
        setNumberOfPages(null);
        setPageNumber(null);
        getTampers(pageSize, 1);
    };

    /**
     * clearSearch Function
     *
     * Clears the search input and fetches tamper data without any vehicle reference filter.
     */
    const clearSearch = () => {
        setVehicleReference('');
        getTampers(pageSize, pageNumber);
    };

    /**
     * handleVehicleReferenceChange Function
     *
     * Updates the 'vehicleReference' state based on user input.
     *
     * @param {Object} event - The input change event.
     */
    const handleVehicleReferenceChange = (event) => {
        setVehicleReference(event.target.value);
    };

    let content = null;
    if (loading) {
        content = <Loading />;
    } else if (error) {
        content = <p>There was an error.</p>;
    } else if (TamperData.length === 0) {
        content = <div className="centered-content"><p>No Data</p></div>;
    } else {
        content = (
            <div className="chart-wrapper-2">
                <table className="table table-striped table-dark">
                    <thead>
                    <tr>
                        <th>Vehicle</th>
                        <th>Device</th>
                        <th>Received</th>
                        <th>Occured</th>
                    </tr>
                    </thead>
                    <tbody>
                    {TamperData.map(tamper => (
                        <tr key={tamper.timestamp}>
                            <td>{tamper.vehicleReference}</td>
                            <td>{tamper.deviceReference}</td>
                            <td>
                                <Moment format="DD/MM/YYYY HH:mm:ss">{tamper.receivedDateTime}</Moment> <br/> (<Moment className="" fromNow>{tamper.receivedDateTime}</Moment>)
                            </td>
                            <td>
                                <Moment format="DD/MM/YYYY HH:mm:ss">{tamper.timestamp}</Moment> <br/> (<Moment className="" fromNow>{tamper.timestamp}</Moment>)
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        );
    }

    return (
        <div className="webcontainer">
            <Container fluid>
                <Row>
                    <Col md={12} lg={12} className="fixed-col2">
                        <div className="contentvehicle3" style={{ textAlign: 'center' }}>
                            <h4>Tampers</h4>
                        </div>
                    </Col>
                    <Col md={12} lg={3}>
                        <div className="info-wrapper">
                            <h5>Search</h5>
                            <Form onSubmit={searchCrashes}>
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Control type="text" value={vehicleReference} onChange={handleVehicleReferenceChange} placeholder="Enter a Vehicle" />
                                </Form.Group>
                            </Form>
                            <Button variant="primary" type="submit" style={{ marginRight: '10px' }} onClick={searchCrashes}>Search</Button>
                            <Button variant="secondary" type="button" onClick={clearSearch}>Clear</Button>
                        </div>
                    </Col>
                    <PagingInfinite
                        content={<div>{content}{fetching && <Loading />}</div>}
                        pageSize={pageSize}
                        pageNumber={pageNumber}
                        totalResults={totalResults}
                        numberOfPages={numberOfPages}
                        fetching={fetching}
                        loadMore={moreToLoad}
                        onChange={(e) => getTampers(pageSize, e)}
                        pageSizeChange={(e) => getTampers(e, 1)}
                        pageSizeSet={(e) => setPageSize(e)}
                    />
                </Row>
            </Container>
        </div>
    );
};

export default TamperReports;
