import React, { useCallback, useEffect, useState } from 'react';
import GetHttpConfig from "../../Helpers/GetHttpConfig";
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import './EditUser.css';

/**
 * AddUser Component
 *
 * Renders a form that allows administrators to add new users by specifying their
 * username, associated company, and preferred measurement unit. It fetches the
 * list of companies from the API upon mounting and handles form submission to
 * create a new user. After successful creation, it navigates to the user management
 * page. The component includes basic error handling by logging errors to the console.
 *
 * @param {Object} props - Component properties.
 * @param {string} props.userAccessToken - User's access token for API authorization.
 *
 * @returns {JSX.Element} The rendered component.
 */
const AddUser = (props) => {
    const [NewUsersData, setNewUsersData] = useState([]);
    const [DefaultCompany, setDefaultCompany] = useState(null);
    const [username, setUsername] = useState("");
    const [companyname, setCompanyname] = useState("");
    const [speedUnit, setSpeedUnit] = useState("0");

    const navigate = useNavigate();

    /**
     * useEffect Hook
     *
     * Sets the document title and fetches all companies when the component mounts.
     */
    useEffect(() => {
        document.title = "Add User";
        getAllCompanies();
    }, []);

    /**
     * Fetches all companies from the API and updates the state.
     *
     * @async
     * @function getAllCompanies
     */
    const getAllCompanies = useCallback(async () => {
        try {
            const response = await fetch(`/api/UserManagement/GetAllCompanies`, GetHttpConfig(props.userAccessToken));
            if (!response.ok) {
                throw new Error("Failed to fetch companies");
            }
            const data = await response.json();
            setNewUsersData(data);
            setDefaultCompany(data[0]?.name);
        } catch (error) {
            console.error(error);
        }
    }, [props.userAccessToken]);

    /**
     * Handles the creation of a new user by submitting the form data to the API.
     *
     * @async
     * @function handleCreateUser
     * @param {Event} e - Form submission event.
     */
    const handleCreateUser = useCallback(async (e) => {
        e.preventDefault();
        let companyName = !companyname || companyname === "Select Group" ? DefaultCompany : companyname;

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + props.userAccessToken
            },
            body: JSON.stringify({
                username,
                companyName,
                isAdmin: false,
                speedUnit
            })
        };

        try {
            const response = await fetch('/api/UserManagement/AddUser', requestOptions);
            if (!response.ok) {
                throw new Error("Failed to add user");
            }
            navigate("/admin/usermanagement");
        } catch (error) {
            console.error(error);
        }
    }, [companyname, DefaultCompany, username, props.userAccessToken, navigate, speedUnit]);

    /**
     * Handles changes to the username input field.
     *
     * @function handleUsernameChange
     * @param {Event} event - Input change event.
     */
    const handleUsernameChange = useCallback((event) => {
        setUsername(event.target.value);
    }, []);

    /**
     * Handles changes to the company select dropdown.
     *
     * @function handleCompanynameChange
     * @param {string} selectedCompany - The selected company name.
     */
    const handleCompanynameChange = useCallback((selectedCompany) => {
        setCompanyname(selectedCompany);
    }, []);

    /**
     * Handles changes to the speed unit select dropdown.
     *
     * @function handleSpeedUnitChange
     * @param {Event} event - Input change event.
     */
    const handleSpeedUnitChange = (event) => {
        setSpeedUnit(event.target.value);
    }

    return (
        <div className="webcontainer">
            <Container fluid>
                <Row>
                    <Col md={12} lg={12} className="fixed-col2">
                        <div className="contentvehicle3">
                            <h4>Add User</h4>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={3} lg={3}></Col>
                    <Col md={6}>
                        <div className="container mt-5">
                            <Form onSubmit={handleCreateUser} className="form-container">
                                {/* Username Input */}
                                <Form.Group controlId="newUsername">
                                    <Form.Label>Username</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={username}
                                        required
                                        placeholder="Enter a Username"
                                        onChange={handleUsernameChange}
                                    />
                                </Form.Group>

                                {/* Company Select Dropdown */}
                                <Form.Group controlId="company">
                                    <Form.Label>Company:</Form.Label>
                                    <Form.Control
                                        as="select"
                                        value={companyname}
                                        onChange={(e) => handleCompanynameChange(e.target.value)}
                                    >
                                        <option>Select Company</option>
                                        {NewUsersData.map(company => (
                                            <option key={company.name} value={company.name}>
                                                {company.name}
                                            </option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>

                                {/* Measurement Unit Select Dropdown */}
                                <Form.Group controlId="speedUnit">
                                    <Form.Label>Measurement Unit</Form.Label>
                                    <Form.Control
                                        as="select"
                                        value={speedUnit}
                                        onChange={handleSpeedUnitChange}
                                    >
                                        <option value="0">Imperial</option>
                                        <option value="1">Metric</option>
                                    </Form.Control>
                                </Form.Group>

                                {/* Submit Button */}
                                <Button variant="primary" type="submit">
                                    Create User
                                </Button>
                            </Form>
                        </div>
                    </Col>
                    <Col md={3} lg={3}></Col>
                </Row>
            </Container>
        </div>
    );
};

export default AddUser;
