import React from 'react';
import { Spinner } from 'react-bootstrap';

/**
 * Loading Component
 *
 * Displays a centered loading spinner to indicate ongoing processes or data fetching.
 *
 * @returns {JSX.Element} The rendered loading spinner component.
 */
const Loading = () => {
    return (
        <div className="center">
            <Spinner animation="border" variant="primary" />
        </div>
    );
};

export default Loading;
