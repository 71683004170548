import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import NavMenu from '../Navigation/NavMenu';
import IsUserInRole from "../Helpers/IsUserInRole";
import './Layout.css';
import Loading from "./Loading";

/**
 * Layout Component
 *
 * Serves as the main layout for the application, managing the visibility of the navigation menu
 * and rendering child components based on the user's authentication status.
 *
 * @param {Object} props - Component properties.
 * @param {UserManager} props.userManager - Instance of the user manager for authentication.
 * @param {boolean} props.userLoggedIn - Indicates if the user is logged in.
 * @param {Object} props.roles - Roles assigned to the user.
 * @param {string} props.authority - Authority URL for authentication.
 * @param {string} props.userAccessToken - Access token for authenticated requests.
 * @param {string} props.userEmail - Email of the logged-in user.
 * @param {React.ReactNode} props.children - Child components to be rendered within the layout.
 *
 * @returns {JSX.Element} The rendered layout component.
 */
const Layout = (props) => {
    const [navVisible, setNavVisible] = useState(() => {
        const savedNavVisible = localStorage.getItem('navVisible');
        return savedNavVisible !== null ? JSON.parse(savedNavVisible) : true;
    });

    /**
     * Toggles the visibility of the navigation menu.
     *
     * Updates the `navVisible` state and persists the new state in localStorage.
     */
    const toggleNav = () => {
        setNavVisible(prevNavVisible => {
            const newNavVisible = !prevNavVisible;
            localStorage.setItem('navVisible', newNavVisible);
            return newNavVisible;
        });
    };

    const { userManager, userLoggedIn, roles, authority, userAccessToken, userEmail } = props;

    if (userManager !== null) {
        return (
            <Container fluid>
                {userLoggedIn && (
                    <Row>
                        <Col sm={12}>
                            <NavMenu
                                manager={userManager}
                                isAdmin={IsUserInRole(roles, "System Admin")}
                                isUser={IsUserInRole(roles, "UBI Access")}
                                authority={authority}
                                userAccessToken={userAccessToken}
                                userEmail={userEmail}
                                navVisible={navVisible}
                                toggleNav={toggleNav}
                            />
                        </Col>
                    </Row>
                )}
                <Row>
                    <Col sm={12}>
                        <div className={`webcontainer1 ${navVisible ? '' : 'full-width'}`}>
                            {props.children}
                        </div>
                    </Col>
                </Row>
            </Container>
        );
    } else {
        return <Loading />;
    }
};

export default Layout;
