import React, { useState, useEffect } from 'react';
import './DeviceOverview.css';
import GetHttpConfig from "../Helpers/GetHttpConfig";
import Loading from "../Common/Loading";
import { Bar, Line } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Button, Col, Container, Row } from "react-bootstrap";
import BackButtonLogoHolder from "../BackButtonLogoHolder/BackButtonLogoHolder";
import GaugeChart from 'react-gauge-chart';
import Moment from "react-moment";
import { getAverageScores, getTotal, mapToLineData } from "./DeviceOverviewUtils";
import { Chart, registerables } from 'chart.js';
import {
    createJourneyCountBar,
    createJourneyDistanceBar,
    createJourneyDurationBar,
    createJourneyPercentagesBarChart,
    createJourneyScoresLineChart,
    createSpeedingAverageLineChart
} from "../ChartsCommon/CommonCharts";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import IsUserInRole from "../Helpers/IsUserInRole";

Chart.register(...registerables);

/**
 * Overview component displays the vehicle's data and charts.
 *
 * @param {Object} props - The component's props.
 * @param {Array} props.roles - The roles of the current user.
 * @param {string} props.userAccessToken - The user's access token.
 * @param {number} props.speedUnit - The speed unit preference (0 for miles, 1 for kilometers).
 * @returns {JSX.Element} The rendered Overview component.
 */
const Overview = ({ roles, userAccessToken, speedUnit }) => {
    const { vehiclereference } = useParams();
    const vehicleReference = vehiclereference;
    const IsAdmin = IsUserInRole(roles, "System Admin");

    const [state, setState] = useState({
        JourneyCountData: [],
        JourneyDistanceData: [],
        JourneyScoresLineData: [],
        JourneyDurationData: [],
        JourneyDurationPercentages: [],
        SpeedingAverage: [],
        VehicleData: {},
        loading: true,
        error: false,
    });

    useEffect(() => {
        /**
         * Fetches the vehicle data and analysis data from the API.
         * Updates the component state with the fetched data.
         */
        const fetchData = async () => {
            try {
                const baseURL = "/api/";
                const deviceAnalysisURL = baseURL + "DeviceAnalysis/";
                const vehicleURL = baseURL + "Vehicle/GetVehicle";
                const params = `?VehicleReference=${vehicleReference}`;
                const httpConfig = GetHttpConfig(userAccessToken);

                const endpoints = [
                    "JourneyCountDevice",
                    "JourneyDistanceTotalDevice",
                    "DeviceScoresTotal",
                    "JourneyDurationTotal",
                    "JourneyDurationPercentages",
                    "SpeedingAverageTotal"
                ];

                const responses = await Promise.all([
                    ...endpoints.map(endpoint => fetch(deviceAnalysisURL + endpoint + params, httpConfig)),
                    fetch(vehicleURL + params, httpConfig)
                ]);

                const data = await Promise.all(responses.map(res => res.json()));
                const [journeyCountData, journeyDistanceData, journeyScoresData, journeyDurationData, journeyDurationPercentages, speedingAverage, vehicleData] = data;
                const journeyScoresLineData = mapToLineData(journeyScoresData);

                setState({
                    JourneyCountData: journeyCountData,
                    JourneyDistanceData: journeyDistanceData,
                    JourneyScoresLineData: journeyScoresLineData,
                    JourneyDurationData: journeyDurationData,
                    JourneyDurationPercentages: journeyDurationPercentages,
                    SpeedingAverage: speedingAverage,
                    VehicleData: vehicleData,
                    loading: false,
                    error: false,
                });
            } catch (error) {
                console.error(error);
                setState({
                    JourneyCountData: [],
                    JourneyDistanceData: [],
                    JourneyScoresLineData: [],
                    JourneyDurationData: [],
                    JourneyDurationPercentages: [],
                    SpeedingAverage: [],
                    VehicleData: {},
                    loading: false,
                    error: true,
                });
            }
        };

        fetchData();
    }, [vehicleReference, userAccessToken]);

    /**
     * Calculates the total journey count from the state data.
     *
     * @returns {number} The total journey count.
     */
    const getTotalJourneyCount = () => {
        return getTotal('JourneyCountData', state);
    };

    /**
     * Calculates the total journey distance from the state data.
     *
     * @returns {number} The total journey distance.
     */
    const getTotalJourneyDistance = () => {
        return getTotal('JourneyDistanceData', state);
    };

    /**
     * Calculates the total journey duration from the state data.
     *
     * @returns {number} The total journey duration.
     */
    const getTotalJourneyDuration = () => {
        return getTotal('JourneyDurationData', state);
    };

    const { loading, error, VehicleData } = state;

    let Content = null;

    if (loading || speedUnit === null) {
        Content = (
            <div className="centered-content">
                <Loading />
            </div>
        );
    } else if (error) {
        Content = (
            <div className="centered-content">
                <p>Error loading data.</p>
            </div>
        );
    } else {
        const {
            JourneyCountData,
            JourneyDistanceData,
            JourneyScoresLineData,
            JourneyDurationData,
            JourneyDurationPercentages,
            SpeedingAverage,
        } = state;

        const { journeyCountBar, journeyBarOptions } = createJourneyCountBar(JourneyCountData);
        const { journeyDistanceBar, journeyDistanceOptions } = createJourneyDistanceBar(JourneyDistanceData, null, speedUnit);
        const { journeyScoresLineChart, journeyScoresLineChartOptions } = createJourneyScoresLineChart(JourneyScoresLineData);
        const { journeyDurationBar, journeyDurationOptions } = createJourneyDurationBar(JourneyDurationData);
        const { journeyPercentagesBarChart, journeyPercentagesBarChartOptions } = createJourneyPercentagesBarChart(JourneyDurationPercentages);
        const { speedingLineChart, speedingLineChartOptions } = createSpeedingAverageLineChart(SpeedingAverage);

        const analysisTypeLabels = {
            3: 'Overall',
            4: 'Acceleration',
            5: 'Braking',
            6: 'Cornering',
            7: 'Speeding'
        };

        const averageScores = getAverageScores(JourneyScoresLineData);

        /**
         * Renders the average score gauges.
         *
         * @returns {JSX.Element} The rendered average score gauges.
         */
        const renderAverageScoreGauges = () => (
            <Row>
                {Object.keys(averageScores).map((analysisTypeId, index) => {
                    if (analysisTypeId !== '3') {
                        return (
                            <Col md={3} lg={3} className="chart-col" key={index}>
                                <div className="gauge-chart-container">
                                    <div className="gauge-chart">
                                        <GaugeChart
                                            id={`gauge-chart${index}`}
                                            nrOfLevels={15}
                                            percent={averageScores[analysisTypeId] / 100}
                                            textColor={"white"}
                                            needleColor={"orange"}
                                            formatTextValue={(value) => `${Math.round(value)}`}
                                            needleTransitionDuration={3000}
                                            colors={["#FF0000", "#FF7F00", "#FFFF00", "#00FF00"]}
                                        />
                                        <div className="gauge-text">
                                            <h3>{analysisTypeLabels[analysisTypeId]}</h3>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        );
                    }
                    return null;
                })}
            </Row>
        );

        const totalJourneyCount = getTotalJourneyCount();

        const conversionFactor = speedUnit === 0 ? 0.621371 : 1;
        const unitLabel = speedUnit === 0 ? "miles" : "kms";
        const totalJourneyDistanceValue = (getTotalJourneyDistance() * conversionFactor).toFixed(1);
        const totalJourneyDistance = `${totalJourneyDistanceValue}`;

        const totalJourneyDuration = getTotalJourneyDuration();

        const averageDistance = totalJourneyCount !== 0 ? (totalJourneyDistanceValue / totalJourneyCount).toFixed(1) : 0;

        let averageDuration = "0h 0m";
        if (totalJourneyCount !== 0) {
            const totalSeconds = totalJourneyDuration / totalJourneyCount;
            const hours = Math.floor(totalSeconds / 3600);
            const minutes = Math.floor((totalSeconds % 3600) / 60);
            averageDuration = `${hours}h ${minutes}m`;
        }

        const deviceReference = VehicleData.deviceReference || 'N/A';
        const createdDateTime = VehicleData.createdDateTime ? new Date(VehicleData.createdDateTime).toLocaleDateString() : 'N/A';
        const lastPacketReceived = VehicleData.lastPacketReceived ? <Moment fromNow>{VehicleData.lastPacketReceived}</Moment> : 'N/A';

        Content = (
            <div>
                <Row>
                    <Col md={12} lg={6} className="chart-col">
                        <div className="chart-wrapper1">
                            <div className="tile">
                                <h5>Vehicle</h5>
                                <div className="tile-content">{vehicleReference}</div>
                            </div>
                            <div className="tile">
                                <h5>Device</h5>
                                <div className="tile-content">{deviceReference}</div>
                            </div>
                            <div className="tile">
                                <h5>Date Created</h5>
                                <div className="tile-content">{createdDateTime}</div>
                            </div>
                            <div className="tile">
                                <h5>Last seen</h5>
                                <div className="tile-content">{lastPacketReceived}</div>
                            </div>
                            <div className="tile">
                                <h5>Journeys</h5>
                                <div className="tile-content">{totalJourneyCount}</div>
                            </div>
                            <div className="tile">
                                <h5>Distance</h5>
                                <div className="tile-content">{totalJourneyDistance} {unitLabel}</div>
                            </div>
                            <div className="tile">
                                <h5>Avg Distance</h5>
                                <div className="tile-content">{averageDistance} {unitLabel}</div>
                            </div>
                            <div className="tile">
                                <h5>Avg Duration</h5>
                                <div className="tile-content">{averageDuration}</div>
                            </div>
                        </div>
                    </Col>
                    <Col md={12} lg={6} className="chart-col">
                        <div className="chart-wrapper">
                            {renderAverageScoreGauges()}
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} lg={4} className="chart-col">
                        <div className="chart-wrapper">
                            <p className="chart-title">Scores</p>
                            <Line data={journeyScoresLineChart} options={journeyScoresLineChartOptions} />
                        </div>
                    </Col>
                    <Col md={12} lg={4} className="chart-col">
                        <div className="chart-wrapper">
                            <p className="chart-title">Journey Count</p>
                            <Bar data={journeyCountBar} options={journeyBarOptions} plugins={[ChartDataLabels]} />
                        </div>
                    </Col>
                    <Col md={12} lg={4} className="chart-col">
                        <div className="chart-wrapper">
                            <p className="chart-title">Duration</p>
                            <Bar data={journeyDurationBar} options={journeyDurationOptions} plugins={[ChartDataLabels]} />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} lg={4} className="chart-col">
                        <div className="chart-wrapper">
                            <p className="chart-title">Speeding Percentage</p>
                            <Bar data={speedingLineChart} options={speedingLineChartOptions} plugins={[ChartDataLabels]} />
                        </div>
                    </Col>
                    <Col md={12} lg={4} className="chart-col">
                        <div className="chart-wrapper">
                            <p className="chart-title">Driving Length</p>
                            <Bar data={journeyPercentagesBarChart} options={journeyPercentagesBarChartOptions} plugins={[ChartDataLabels]} />
                        </div>
                    </Col>
                    <Col md={12} lg={4} className="chart-col">
                        <div className="chart-wrapper">
                            <p className="chart-title">Distance ({unitLabel})</p>
                            <Bar data={journeyDistanceBar} options={journeyDistanceOptions} plugins={[ChartDataLabels]} />
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }

    return (
        <div className="webcontainer">
            <Container fluid>
                <Row>
                    <Col md={12} lg={4}>
                        <div className="d-flex justify-content-left" style={{ marginBottom: '10px', marginTop: '10px' }}>
                            <BackButtonLogoHolder backlink="/vehicle-list" />
                            <Link to={`/vehicles/${vehicleReference}`}>
                                <Button variant="primary" style={{ marginRight: '10px', marginLeft: '10px' }}>
                                    Journeys
                                </Button>
                            </Link>
                            {IsAdmin && (
                                <Link to={`/vehiclesDebug/${vehicleReference}`}>
                                    <Button variant="warning" style={{ marginRight: '10px', marginLeft: '0px' }}>
                                        Debug
                                    </Button>
                                </Link>
                            )}
                        </div>
                    </Col>
                    <Col md={6} lg={4} className="fixed-col2" style={{ marginBottom: '10px', marginTop: '10px' }}>
                        <div className="contentvehicle3" style={{ marginTop: '10px' }}>
                            <h4>Vehicle: {vehicleReference}</h4>
                        </div>
                    </Col>
                </Row>
                {Content}
            </Container>
        </div>
    );
};

export default Overview;