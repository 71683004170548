import { getPastTwelveMonths } from "../DeviceOverview/DeviceOverviewUtils";

/**
 * Retrieves an array of monthly data values from a data object.
 *
 * @param {Object} dataObj - The data object containing monthly values.
 * @returns {number[]} An array of monthly data values.
 */
function getMonthlyData(dataObj) {
    return Array.from({ length: 12 }, (_, i) => dataObj?.[`month${11 - i}`] || 0);
}

/**
 * Calculates the maximum Y-axis value for a chart, adjusted if all values are zero.
 *
 * @param {number[]} data - The data array to evaluate.
 * @returns {number} The maximum Y-axis value.
 */
function getMaxYValue(data) {
    const allValuesAreZero = data.every(val => val === 0);
    return allValuesAreZero ? 1 : Math.max(...data) * 1.2;
}

/**
 * Formats a dataset object for chart configuration.
 *
 * @param {Object} params - Parameters for formatting the dataset.
 * @param {string} params.label - The label for the dataset.
 * @param {number[]} params.data - The data array for the dataset.
 * @param {string} params.backgroundColor - Background color for the dataset.
 * @param {string} [params.borderColor] - Border color for the dataset.
 * @param {number} [params.borderWidth=1] - Border width for the dataset.
 * @returns {Object} The formatted dataset object.
 */
function formatDataset({ label, data, backgroundColor, borderColor = backgroundColor, borderWidth = 1 }) {
    return {
        label,
        data,
        backgroundColor,
        borderColor,
        borderWidth,
        hoverBackgroundColor: backgroundColor,
        hoverBorderColor: borderColor,
    };
}

/**
 * Creates the chart data and options for the Active Devices Bar chart.
 *
 * @param {Object} ActiveDevicesData - The active devices data object containing monthly values.
 * @returns {Object} An object containing `activeDevicesBar` data and `activeDevicesOptions`.
 */
export function createActiveDevicesBar(ActiveDevicesData) {
    const labels = getPastTwelveMonths();
    const data = getMonthlyData(ActiveDevicesData);
    const maxYValue = getMaxYValue(data);

    const activeDevicesBar = {
        labels,
        datasets: [
            formatDataset({
                label: 'Active Devices Data',
                data,
                backgroundColor: 'rgba(153,102,255,1)',
            }),
        ],
    };

    let delayed;

    const activeDevicesOptions = {
        plugins: {
            datalabels: {
                display: true,
                color: 'white',
                align: 'end',
                anchor: 'end',
                clamp: true,
                formatter: value => (value === 0 ? '' : Math.round(value)),
            },
            legend: { display: false },
            tooltip: { enabled: false },
        },
        animation: {
            delay: context => {
                let delay = 0;
                if (context.type === 'data' && context.mode === 'default' && !delayed) {
                    delay = context.dataIndex * 30 + context.datasetIndex * 10;
                }
                return delay;
            },
        },
        scales: {
            y: {
                beginAtZero: true,
                max: maxYValue,
                display: false,
            },
            x: {
                ticks: {
                    color: 'white',
                },
            },
        },
    };

    return { activeDevicesBar, activeDevicesOptions };
}

/**
 * Calculates the journey count data per device or total.
 *
 * @param {Object} JourneyCountData - The journey count data object.
 * @param {Object|null} ActiveDevicesData - The active devices data object, or null.
 * @returns {number[]} An array of journey count data values.
 */
function getJourneyCountData(JourneyCountData, ActiveDevicesData = null) {
    return Array.from({ length: 12 }, (_, i) => {
        const index = 11 - i;
        const journeyCount = JourneyCountData?.[`month${index}`] || 0;
        if (ActiveDevicesData === null) {
            return journeyCount;
        } else {
            const activeDevices = ActiveDevicesData[`month${index}`] || 0;
            return activeDevices > 0 ? journeyCount / activeDevices : 0;
        }
    });
}

/**
 * Creates the chart data and options for the Journey Count Bar chart.
 *
 * @param {Object} JourneyCountData - The journey count data object containing monthly values.
 * @param {Object|null} ActiveDevicesData - The active devices data object, or null.
 * @returns {Object} An object containing `journeyCountBar` data and `journeyBarOptions`.
 */
export function createJourneyCountBar(JourneyCountData, ActiveDevicesData = null) {
    const labels = getPastTwelveMonths();
    const data = getJourneyCountData(JourneyCountData, ActiveDevicesData);
    const maxYValue = getMaxYValue(data);

    const journeyCountBar = {
        labels,
        datasets: [
            formatDataset({
                label: 'Journey Count Data',
                data,
                backgroundColor: '#0f8dff',
                borderColor: '#0f8dff',
                borderWidth: 1,
            }),
        ],
    };

    const journeyBarOptions = {
        plugins: {
            datalabels: {
                display: true,
                color: 'white',
                align: 'end',
                anchor: 'end',
                clamp: true,
                formatter: value => (value === 0 ? '' : Math.round(value)),
            },
            legend: { display: false },
            tooltip: { enabled: false },
        },
        scales: {
            y: {
                max: maxYValue,
                display: false,
            },
            x: {
                ticks: {
                    font: {
                        size: 12,
                    },
                    color: 'white',
                },
            },
        },
    };

    return { journeyCountBar, journeyBarOptions };
}

/**
 * Calculates the journey duration data per device or total.
 *
 * @param {Object} TotalDurationMonth - The total journey duration data object.
 * @param {Object|null} ActiveDevicesData - The active devices data object, or null.
 * @returns {number[]} An array of journey duration data values.
 */
function getJourneyDurationData(TotalDurationMonth, ActiveDevicesData = null) {
    return Array.from({ length: 12 }, (_, i) => {
        const index = 11 - i;
        const totalDuration = TotalDurationMonth?.[`month${index}`] || 0;
        if (ActiveDevicesData === null) {
            return totalDuration;
        } else {
            const activeDevices = ActiveDevicesData[`month${index}`] || 0;
            return activeDevices > 0 ? totalDuration / activeDevices : 0;
        }
    });
}

/**
 * Creates the chart data and options for the Journey Duration Total Bar chart.
 *
 * @param {Object} TotalDurationMonth - The total journey duration data object containing monthly values.
 * @param {Object|null} ActiveDevicesData - The active devices data object, or null.
 * @returns {Object} An object containing `journeyDurationTotalBar` data and `journeyDurationBarOptions`.
 */
export function createJourneyDurationTotalBar(TotalDurationMonth, ActiveDevicesData = null) {
    const labels = getPastTwelveMonths();
    const data = getJourneyDurationData(TotalDurationMonth, ActiveDevicesData);
    const maxYValue = getMaxYValue(data);

    const journeyDurationTotalBar = {
        labels,
        datasets: [
            formatDataset({
                label: 'Journey Duration Data',
                data,
                backgroundColor: '#05b053',
            }),
        ],
    };

    const journeyDurationBarOptions = {
        plugins: {
            datalabels: {
                display: true,
                color: 'white',
                align: 'end',
                anchor: 'end',
                formatter: function (value) {
                    if (value === 0) {
                        return '';
                    } else if (value >= 86400) {
                        const days = Math.floor(value / 86400);
                        return `${days}d`;
                    } else if (value >= 3600) {
                        const hours = Math.floor(value / 3600);
                        return `${hours}h`;
                    } else {
                        const minutes = Math.floor(value / 60);
                        return `${minutes}m`;
                    }
                },
            },
            legend: { display: false },
            tooltip: { enabled: false },
        },
        scales: {
            y: {
                max: maxYValue,
                display: false,
            },
            x: {
                ticks: {
                    font: {
                        size: 12,
                    },
                    color: 'white',
                },
            },
        },
    };

    return { journeyDurationTotalBar, journeyDurationBarOptions };
}