/**
 * Checks if a user has a specific role.
 *
 * @param {Array} roles - List of roles assigned to the user.
 * @param {string} roleName - Role to check for.
 * @returns {boolean} True if the user has the specified role, otherwise false.
 */
const IsUserInRole = (roles, roleName) => {
    return roles && Array.isArray(roles) && roles.indexOf(roleName) > -1;
    
};

export default IsUserInRole;